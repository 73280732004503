export let WholesaleListActionTypes;
(function (WholesaleListActionTypes) {
  WholesaleListActionTypes["WHOLESALE_LIST_GET_LOADING"] = "WHOLESALE_LIST_GET_LOADING";
  WholesaleListActionTypes["WHOLESALE_LIST_GET_SUCCESS"] = "WHOLESALE_LIST_GET_SUCCESS";
  WholesaleListActionTypes["WHOLESALE_LIST_GET_ERROR"] = "WHOLESALE_LIST_GET_ERROR";
  WholesaleListActionTypes["WHOLESALE_LIST_CHANGE_PAGE_NUM"] = "WHOLESALE_LIST_CHANGE_PAGE_NUM";
  WholesaleListActionTypes["WHOLESALE_LIST_CHANGE_SEARCH_TERM"] = "WHOLESALE_LIST_CHANGE_SEARCH_TERM";
  WholesaleListActionTypes["WHOLESALE_LIST_CHANGE_STATUS_FILTERS"] = "WHOLESALE_LIST_CHANGE_STATUS_FILTERS";
  WholesaleListActionTypes["WHOLESALE_LIST_CHANGE_INGESTION_CHANNEL_FILTERS"] = "WHOLESALE_LIST_CHANGE_INGESTION_CHANNEL_FILTERS";
  WholesaleListActionTypes["WHOLESALE_LIST_SET_TAB_FILTER"] = "WHOLESALE_LIST_SET_TAB_FILTER";
})(WholesaleListActionTypes || (WholesaleListActionTypes = {}));