export let ListType;
(function (ListType) {
  ListType["Inventory"] = "inventoryList";
  ListType["InventoryV2"] = "inventoryListV2";
  ListType["Bundle"] = "bundleList";
  ListType["BundleV2"] = "bundleListV2";
  ListType["OrderIngestErrors"] = "orderIngestErrors";
  ListType["Orders"] = "orderList";
  ListType["Inbounds"] = "inboundList";
  ListType["Parcel"] = "parcelList";
  ListType["Prep"] = "prepList";
  ListType["ClaimUploads"] = "claimUploadsList";
  ListType["ReserveStorageOutbounds"] = "reserveStorageOutboundList";
  ListType["InboundListV2"] = "inboundListV2";
  ListType["Carriers"] = "carriersList";
})(ListType || (ListType = {}));
export const appendListType = (actionType, listType) => `${actionType}_${listType.toUpperCase()}`;