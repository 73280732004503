import { isMilitaryAddress, isAddressInAkHiOther } from "@deliverr/commons-utils";
import { doesAddressContainPOBox, isInternationalAddress } from "common/AddressUtils";
import { notifyUserOfError } from "common/ErrorToast";
import { getEstimatedCosts, getRemovalCosts } from "common/ProductUtils";
import { map, pick, prop, isMatch } from "lodash/fp";
import log from "Logger";
import { setShippingCosts, setShippingMethod, reviewOrder } from "order/new/NewOrderActions";
import { parse, stringify } from "qs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAsync, useDebounce, usePrevious } from "react-use";
import { RemovalMethods, removalMethods, shippingMethodsMap, contiguousUSShippingMethods, legacyContiguousUSShippingMethods } from "../../ShippingMethods";
import { pickAll } from "common/utils/helpers";
import { ServiceLevel, RemovalItemType } from "@deliverr/billing-pricer-client";
import { getApprovedHazmatInfo } from "common/utils/product/getApprovedHazmatInfo";
import { getSellerId } from "common/user/UserSelectors";
import { isDisposalOrder } from "order/detail/helpers/isDisposalOrder";
import { useHistory } from "react-router-dom";
import { SELLER_IDS_ON_2023_PRICING } from "common/utils/serviceLevels/serviceLevels";
const productDims = pick(["dsku", "weight", "height", "length", "width"]);
export const getShippingOptionsByAddress = (address, sellerId) => {
  // The order of these checks matter because there can be overlap between them
  if (isMilitaryAddress(address)) {
    return [shippingMethodsMap[ServiceLevel.Military]];
  }
  if (isAddressInAkHiOther(address)) {
    return [shippingMethodsMap[ServiceLevel.AKHIOtherNonFastTag]];
  }
  if (isInternationalAddress(address)) {
    return [shippingMethodsMap[ServiceLevel.WorldwideStandardDeliveredDutyUnpaid]];
  }
  if (SELLER_IDS_ON_2023_PRICING.includes(sellerId)) {
    if (doesAddressContainPOBox(address)) {
      return [shippingMethodsMap[ServiceLevel.StandardNonFastTag]];
    }
    return legacyContiguousUSShippingMethods;
  } else {
    if (doesAddressContainPOBox(address)) {
      return [shippingMethodsMap[ServiceLevel.Standard]];
    }
    return contiguousUSShippingMethods;
  }
};
const lookupPromiseDates = async (address, methods) => {
  const ctx = {
    fn: "lookupPromiseDates",
    address
  };
  log.info(ctx, "fetching timezone lookup from lambda");
  try {
    const params = `${stringify(address)}&${stringify({
      methods
    })}`;
    const res = await fetch(`https://lr4xx8scdh.execute-api.us-east-1.amazonaws.com/latest/timezonelookup?${params}`);
    const promiseDates = await res.json();
    return promiseDates;
  } catch (err) {
    log.error({
      ...ctx,
      err
    }, "error fetching timezone");
    notifyUserOfError({
      toastId: "lookupPromiseDates",
      translation: {
        id: "orders.orderDetail.promiseByError",
        defaultMessage: "There was an error trying to calculate your promised by date."
      }
    });
  }
};
export const useNewOrderSelectMethod = () => {
  const {
    orderDetail: {
      order
    },
    orderNew
  } = useSelector(state => ({
    orderDetail: state.orderDetail,
    orderNew: state.orderNew
  }));
  const sellerId = useSelector(getSellerId);
  const history = useHistory();
  const {
    address,
    productDetails,
    items,
    isRemoval
  } = orderNew;
  const isDisposal = isDisposalOrder(order);
  const dispatch = useDispatch();
  const [promiseDates, setPromiseDates] = useState({});
  const shippingOptions = isRemoval ? removalMethods : getShippingOptionsByAddress(address, sellerId);
  const methods = map(prop("requiredDeliveryInDays"), shippingOptions);
  const [filteredAddress, setFilteredAddress] = useState(address);
  const prevFilteredAddress = usePrevious(filteredAddress);
  useEffect(() => {
    if (isRemoval) {
      dispatch(setShippingMethod(RemovalMethods.REMOVAL));
    }
  }, [isRemoval, dispatch]);

  // watch only certain fields of address to control the amount of calls to the lambda
  useEffect(() => {
    const filtered = pickAll(["street1", "city", "state", "zip", "country", "street2"], address);
    if (!isMatch(prevFilteredAddress, filtered)) {
      setFilteredAddress(filtered);
    }
  }, [address, prevFilteredAddress]);
  useDebounce(async () => {
    const res = await lookupPromiseDates(filteredAddress, methods);
    setPromiseDates(res);
  }, 800, [filteredAddress]);
  useAsync(async () => {
    const {
      available = "1"
    } = parse(history.location.search.slice(1));
    const lotAvailableQty = parseInt(available);
    const productCostParams = items.map(item => ({
      ...productDims(productDetails[item.dsku]),
      qty: orderNew.isLotRemoval ? lotAvailableQty : item.qty,
      hazmatIds: getApprovedHazmatInfo(productDetails[item.dsku]),
      minimumPackagingType: productDetails[item.dsku].sellerShippingSpecification?.minimumPackagingType
    }));
    let costs;
    if (isRemoval) {
      const removalType = isDisposal ? RemovalItemType.DISPOSAL_REMOVAL : RemovalItemType.INVENTORY_REMOVAL;
      const removalMethod = isDisposal ? RemovalMethods.DISPOSAL : RemovalMethods.REMOVAL;
      costs = {
        [removalMethod]: await getRemovalCosts({
          priceItems: productCostParams,
          address: address,
          removalType,
          sellerId
        })
      };
    } else {
      const results = await Promise.all(Object.values(shippingOptions).map(async ({
        name
      }) => ({
        [name]: await getEstimatedCosts({
          items: productCostParams,
          showNewPrices: false,
          address,
          serviceLevel: name,
          countryCode: address?.country,
          sellerId
        })
      })));
      costs = results.reduce((acc, val) => val ? {
        ...acc,
        ...val
      } : acc, {});
    }
    if (costs) {
      dispatch(setShippingCosts(costs));
    }
  }, [isRemoval, address, items, productDetails, dispatch]);
  const handleShippingMethod = method => dispatch(setShippingMethod(method));
  const handleReviewOrder = () => dispatch(reviewOrder());
  return {
    orderNew,
    shippingOptions,
    promiseDates,
    handleShippingMethod,
    handleReviewOrder
  };
};