import useSWR from "swr";
import { toast } from "common/components/ui";
import { useIntl } from "react-intl";
import { logStart, logError } from "Logger";
import AlgoliaService from "common/list/AlgoliaService";
export function useAlgoliaService({
  serviceConfig,
  searchTerm,
  filters,
  refreshIntervalMs,
  allowEmptySearchTerm,
  isAlgoliaSearchEnabled = true
}) {
  const {
    formatMessage
  } = useIntl();
  const ctx = {
    fn: "useAlgoliaService",
    serviceConfig,
    searchTerm,
    filters
  };
  const algoliaService = AlgoliaService.get(serviceConfig);
  const isValidSearchTerm = searchTerm !== null && (searchTerm.length > 0 || allowEmptySearchTerm);
  const key = isValidSearchTerm && isAlgoliaSearchEnabled ? ["algoliaSearch", serviceConfig, searchTerm] : null;
  const {
    error,
    data
  } = useSWR(key, async ([_, _config, term]) => {
    logStart(ctx);
    algoliaService.clearCache();
    return await algoliaService.search(term, undefined, undefined, filters);
  }, {
    onError: err => {
      logError(ctx, err, `error retrieving ${serviceConfig.indexName}`);
      toast.error(formatMessage({
        id: "search.errorMessage",
        defaultMessage: "Error searching"
      }), {
        toastId: "algoliaSearchError"
      });
    },
    revalidateOnFocus: false,
    // don't refresh automatically when the seller returns focus to the page
    refreshInterval: refreshIntervalMs
  });
  return {
    data,
    error
  };
}