import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { initiateDownloadFromUrl } from "common/InitiateDownload";
import { toast } from "common/components/ui";
import { getStorageInboundDetail } from "../../../store";
import { freightClient } from "Clients";
import { getSellerId } from "common/user/UserSelectors";
export const useDownloadBOL = () => {
  const {
    formatMessage
  } = useIntl();
  const {
    storageInboundOrder
  } = useSelector(getStorageInboundDetail);
  const sellerId = useSelector(getSellerId);
  const freightOrderId = storageInboundOrder?.freightOrderDetails?.orderId;
  const onDownloadClick = async () => {
    if (freightOrderId) {
      const freightOrder = await freightClient.getOrderDetails(Number(freightOrderId), sellerId);
      const storageLabelsResponse = freightOrder.orderItems?.[0]?.customerBolId ? await freightClient.getBol(Number(freightOrderId), freightOrder.orderItems?.[0]?.customerBolId, sellerId) : undefined;
      const labelUrl = storageLabelsResponse?.customerBolUrl;
      if (labelUrl) {
        initiateDownloadFromUrl(labelUrl, `FlexportPalletLabels-${storageInboundOrder.shipmentId}.pdf`);
        toast.success(formatMessage({
          id: "storage.inbounds.details.label.bol.download.success",
          defaultMessage: "Bill of lading downloaded"
        }));
      } else {
        toast.error(formatMessage({
          id: "storage.inbounds.details.label.bol.download.error",
          defaultMessage: "Bill of lading download failed"
        }));
      }
    }
  };
  return {
    onDownloadClick
  };
};