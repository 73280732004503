import { DistributionType, UnitSystem, ShipmentSteps, CargoInputType } from "../CreateShipmentTypes";
export const createShipmentInitialFormState = {
  originType: null,
  isOriginPort: null,
  origin: {
    label: "",
    value: {
      address: {
        name: "",
        street1: "",
        street2: "",
        city: "",
        zip: "",
        state: "",
        country: "",
        countryCode: "",
        latLng: undefined
      },
      ports: [],
      distanceKm: 0
    }
  },
  originFacilityName: null,
  supplier: {
    companyName: "",
    contactEmail: "",
    companyEntityFid: "",
    contactFid: "",
    contactName: "",
    contactPhone: ""
  },
  destination: {
    label: "",
    value: {
      address: {
        name: "",
        street1: "",
        street2: "",
        city: "",
        zip: "",
        state: "",
        country: "",
        countryCode: "",
        latLng: undefined
      },
      ports: [],
      distanceKm: 0
    }
  },
  destinationFacilityName: null,
  destinationAddressFid: null,
  isLiftgateRequired: null,
  deliveryDate: {
    date: null,
    isFlexible: false
  },
  cargoInputType: CargoInputType.PRODUCTS,
  isCargoMixedSku: false,
  bookingProducts: [],
  singleSkuPackages: {},
  mixedSkuPackages: [],
  airShipmentCargoDetails: [],
  unitSystem: UnitSystem.CBM_KG,
  shipmentTotals: {
    volume: 0,
    weight: 0,
    pallets: 0,
    boxes: 0,
    units: 0
  },
  cargoReadyDate: null,
  hasDangerousGoods: null,
  dangerousGoods: {
    hasLithiumBatteries: false,
    hasNonLithiumBatteries: false,
    hasOther: false,
    hasNoDangerousGoods: false
  },
  lithiumBatteryPackaging: {
    packedLoose: false,
    packedEquipment: false,
    inEquipment: false,
    inVehicle: false
  },
  destinationSelect: {
    isByFlexport: false,
    isByAmazon: false,
    isDTCNetwork: false,
    isOwnDestination: false
  },
  hasAnnualCustomsBond: false,
  flexportCapitalSelected: false,
  distributionType: DistributionType.REPLENISH_ON_DEMAND,
  amazonDestinationType: null,
  amazonPrepType: null,
  amazonAddress: null,
  reserveStorage: {
    address: null,
    warehouseId: null
  },
  crossdockWarehouse: {
    address: null,
    warehouseId: null
  },
  freightService: null,
  isOwnTransportation: false,
  domesticDestinationType: null,
  hasCargoInsurance: false,
  cargoInsuranceType: null,
  dtcPrepOption: {
    boxLabelingUnitsSelected: false,
    unitPrepService: null
  },
  insuranceInfo: {
    totalCargoValue: 0,
    totalInsuranceValueCents: 0,
    insurancePremiumCents: 0
  }
};
export const createShipmentInitialMetaState = {
  stepStack: [ShipmentSteps.OriginSearch],
  shippingPlanId: null,
  isSaving: false,
  isLoading: false,
  isIpbDevToolsHidden: false,
  isReturningUser: false,
  savedFreightServiceTitle: null
};
export const createShipmentInitialState = {
  meta: createShipmentInitialMetaState,
  form: createShipmentInitialFormState
};