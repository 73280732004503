import { dateToOpenSearchFilter } from "./dateToOpenSearchFilter";
import { serviceToOpenSearchFilter } from "./serviceToOpenSearchFilter";
import { statusToOpenSearchFilter } from "./statusToOpenSearchFilter";
import { OrderTag } from "@deliverr/commons-objects";
import { SupportedOrderImportErrorTypes } from "../OrderImportErrorsTable/SupportedOrderImportErrorTypes";
const noDistributionOrderFilter = {
  bool: {
    must_not: {
      match: {
        tags: `${OrderTag.DISTRIBUTION_ORDER}`
      }
    }
  }
};
export const getCustomizedOpenSearchFilters = ({
  status,
  serviceLevel,
  date,
  isAdmin = false
}) => {
  const orderStatusFilters = statusToOpenSearchFilter(status);
  const orderServiceFilters = serviceToOpenSearchFilter(serviceLevel);
  const orderDateFilters = dateToOpenSearchFilter(date);
  // admins should be able to view distribution orders
  const distributionFilter = isAdmin ? [] : [noDistributionOrderFilter];
  const filters = [...orderStatusFilters, ...orderServiceFilters, ...orderDateFilters, ...distributionFilter];
  if (filters.length < 1) {
    return [];
  }
  return [{
    bool: {
      filter: [...orderStatusFilters, ...orderServiceFilters, ...orderDateFilters, ...distributionFilter]
    }
  }];
};
export const getOrderImportErrorCustomizedFilters = () => {
  return [{
    bool: {
      filter: {
        terms: {
          errorType: SupportedOrderImportErrorTypes.map(error => `${error}`)
        }
      }
    }
  }];
};