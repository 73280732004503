import { toTitleCase, validZipCanada, validZipUS, validateZip } from "common/StringUtils";
import { US_STATES } from "@deliverr/commons-utils/lib/AddressUtils";
import _ from "lodash";
import { hasNonExtendedASCIIChars } from "common/utils/hasNonExtendedASCIIChars";
import { isValidPhoneNumber } from "common/forms/PhoneUtils";
const OPTIONAL_FIELDS = ["company", "street2", "phone"];
// Copied from commons-utils because it wasn"t exported:
export const MILITARY_STATES = {
  "Armed Forces Americas (AA)": "AA",
  "Armed Forces Europe (AE)": "AE",
  "Armed Forces Pacific (AP)": "AP"
};
export const US_TERRITORIES = {
  "AMERICAN SAMOA": "AS",
  "DISTRICT OF COLUMBIA": "DC",
  GUAM: "GU",
  "NORTHERN MARIANA ISLANDS": "MP",
  "PUERTO RICO": "PR",
  "VIRGIN ISLANDS": "VI"
};
export const CA_PROVINCES_TERRITORIES = {
  Alberta: "AB",
  "British Columbia": "BC",
  Manitoba: "MB",
  "New Brunswick": "NB",
  "Newfoundland and Labrador": "NL",
  "Northwest Territories": "NT",
  "Nova Scotia": "NS",
  Nunavut: "NU",
  Ontario: "ON",
  "Prince Edward Island": "PE",
  Quebec: "QC",
  Saskatchewan: "SK",
  Yukon: "YT"
};
export const sortAndCapitalize = obj => {
  return Object.entries(obj).sort(([keyA], [keyB]) => keyA.localeCompare(keyB)).reduce((acc, [key, value]) => {
    acc[toTitleCase(key)] = value;
    return acc;
  }, {});
};
export const US_STATES_TERRITORIES_MILITARY = {
  ...sortAndCapitalize({
    ...US_STATES,
    ...US_TERRITORIES
  }),
  ...MILITARY_STATES
};
const FIELD_LIMITS = {
  name: {
    min: 1,
    max: 35
  },
  company: {
    min: 0,
    max: 30
  },
  street1: {
    min: 3,
    max: 35
  },
  street2: {
    min: 0,
    max: 35
  },
  city: {
    min: 2,
    max: 25
  },
  state: {
    min: 1,
    max: 35
  },
  country: {
    min: 2,
    max: 2
  },
  zip: {
    min: 1,
    max: 10
  },
  phone: {
    min: 0,
    max: 26
  }
};
export const checkForAddressErrors = (address, validationFlags = {}, errors = {}) => {
  if (_.isEmpty(validationFlags)) {
    return errors;
  }
  Object.entries(validationFlags).forEach(([fieldName, value]) => {
    if (value) {
      errors[fieldName] = validateField(fieldName, address[fieldName], address.country).error;
    }
  });
  return errors;
};
export const validateField = (field, value, country) => {
  const trimmedValue = value ? value.trim() : "";
  // specific validations for US and CA state and zip
  if (country === "US" || country === "CA") {
    if (field === "state" && !isValidState(trimmedValue, country)) {
      return {
        isValid: false,
        error: "Invalid state"
      };
    }
    if (field === "zip" && !isValidZip(trimmedValue, country)) {
      return {
        isValid: false,
        error: "Invalid zip code"
      };
    }
  }

  // all other validations
  if (OPTIONAL_FIELDS.includes(field) && !value) {
    return {
      isValid: true,
      error: undefined
    };
  }
  const {
    min,
    max
  } = FIELD_LIMITS[field];
  const rules = createFieldValidationRules(field, min, max);
  for (const rule of rules) {
    if (!rule.validate(trimmedValue, country)) {
      return {
        isValid: false,
        error: rule.error
      };
    }
  }
  if (field === "phone") {
    const isValid = country ? isValidPhoneNumber(trimmedValue, {
      countryCode: country,
      shouldAllowEmpty: true
    }) : isValidPhoneNumber(trimmedValue, {
      shouldAllowEmpty: true
    });
    if (!isValid) {
      return {
        isValid: false,
        error: "Invalid phone number"
      };
    }
  }
  return {
    isValid: true,
    error: undefined
  };
};
const createFieldValidationRules = (fieldName, minLength, maxLength) => {
  const capitalizedField = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
  return [{
    validate: value => value.length >= minLength,
    error: minLength === 1 ? `${capitalizedField} is required` : `${capitalizedField} must be at least ${minLength} characters long`
  }, {
    validate: value => value.length <= maxLength,
    error: `${capitalizedField} cannot exceed ${maxLength} characters`
  }, {
    validate: value => !hasNonExtendedASCIIChars(value),
    error: `${capitalizedField} cannot contain non-ASCII characters`
  }];
};

// validates zip for US and CA only
const isValidZip = (zip, country) => {
  if (country.toUpperCase() === "US") {
    return validateZip(zip.trim(), [validZipUS]);
  }
  if (country.toUpperCase() === "CA") {
    return validateZip(zip.trim(), [validZipCanada]);
  }
  return true;
};

// only checks for valid 2-letter US state abbreviation for now
const isValidState = (state, country) => {
  const trimmedState = state.trim().toUpperCase();
  if (country.toUpperCase() === "US") {
    if (trimmedState.length === 2) {
      return Object.values(US_STATES_TERRITORIES_MILITARY).includes(trimmedState);
    }
    if (trimmedState.length < 2) {
      return false;
    }
    if (["US", "UNITED STATES", "USA"].includes(trimmedState)) {
      return false;
    }
  }
  if (country.toUpperCase() === "CA") {
    if (trimmedState.length === 2) {
      return Object.values(CA_PROVINCES_TERRITORIES).includes(trimmedState);
    }
    if (trimmedState.length < 2) {
      return false;
    }
  }
  return true;
};