export let ReplenishmentListActionTypes;
(function (ReplenishmentListActionTypes) {
  ReplenishmentListActionTypes["REPLENISHMENT_LIST_GET_LOADING"] = "REPLENISHMENT_LIST_GET_LOADING";
  ReplenishmentListActionTypes["REPLENISHMENT_LIST_GET_SUCCESS"] = "REPLENISHMENT_LIST_GET_SUCCESS";
  ReplenishmentListActionTypes["REPLENISHMENT_LIST_GET_ERROR"] = "REPLENISHMENT_LIST_GET_ERROR";
  ReplenishmentListActionTypes["REPLENISHMENT_LIST_CHANGE_PAGE_NUM"] = "REPLENISHMENT_LIST_CHANGE_PAGE_NUM";
  ReplenishmentListActionTypes["REPLENISHMENT_LIST_CHANGE_SEARCH_TERM"] = "REPLENISHMENT_LIST_CHANGE_SEARCH_TERM";
  ReplenishmentListActionTypes["REPLENISHMENT_LIST_CHANGE_DESTINATION_FILTERS"] = "REPLENISHMENT_LIST_CHANGE_DESTINATION_SEARCH_FILTERS";
  ReplenishmentListActionTypes["REPLENISHMENT_LIST_CHANGE_STATUS_FILTERS"] = "REPLENISHMENT_LIST_CHANGE_STATUS_SEARCH_FILTERS";
  ReplenishmentListActionTypes["DELETE_REPLENISHMENT_ORDER_SUCCESS"] = "DELETE_REPLENISHMENT_ORDER_SUCCESS";
  ReplenishmentListActionTypes["REPLENISHMENT_LIST_SET_TAB_FILTER"] = "REPLENISHMENT_LIST_SET_TAB_FILTER";
  ReplenishmentListActionTypes["UNSET_SHOW_CHANNEL_REPLENISHMENT_BANNER"] = "UNSET_SHOW_CHANNEL_REPLENISHMENT_BANNER";
})(ReplenishmentListActionTypes || (ReplenishmentListActionTypes = {}));