import { OpenSearchService } from "./OpenSearchService";
import log from "Logger";
import { ARCHIVED, BOOLEAN_ATTRIBUTES, IGNORE_ARCHIVED, NUMERIC_ATTRIBUTES, TEXT_ATTRIBUTES } from "../../../inbounds/constants/InboundLogisticsSearchConstants";
export class InboundsSearchService extends OpenSearchService {
  buildSearchOptions(request) {
    const searchOptions = super.buildSearchOptions(request);
    const shouldQueries = [];
    const mustNot = [];
    const must = [];
    const filterQueries = [];
    log.info({
      request
    }, "Received Request to OpenSearch");
    let searchTerm = request.searchTerm;
    if (searchTerm?.includes(IGNORE_ARCHIVED)) {
      mustNot.push({
        term: {
          shippingPlanStatus: ARCHIVED
        }
      });
      searchTerm = searchTerm.replace(IGNORE_ARCHIVED, "");
    }
    if (searchTerm) {
      const searchQueries = [];
      TEXT_ATTRIBUTES.forEach(field => {
        searchQueries.push({
          query_string: {
            query: `*${searchTerm}*`,
            fields: [field],
            analyze_wildcard: true
          }
        });
      });
      if (this.isNumeric(searchTerm)) {
        NUMERIC_ATTRIBUTES.forEach(field => {
          searchQueries.push({
            match: {
              [field]: {
                query: searchTerm
              }
            }
          });
        });
      }
      must.push({
        dis_max: {
          queries: searchQueries
        }
      });
    }
    if (request?.filters) {
      this.parseAndSplitFilters(request.filters).forEach(filter => {
        if (filter.startsWith("(NOT")) {
          this.handleNegationCases(filter, mustNot);
        } else if (filter.includes(" OR ")) {
          this.handleOrConditions(filter, filterQueries);
        } else {
          this.handleSimpleConditions(filter, filterQueries);
        }
      });
    }
    const sort = [{
      _score: {
        order: "desc"
      }
    }, {
      shippingPlanCreatedAtUnix: {
        order: "desc"
      }
    }];
    return {
      ...searchOptions,
      query: {
        bool: {
          must: must.length > 0 ? must : undefined,
          must_not: mustNot.length > 0 ? mustNot : undefined,
          should: shouldQueries.length > 0 ? shouldQueries : undefined,
          filter: filterQueries.length > 0 ? filterQueries : undefined,
          minimum_should_match: shouldQueries.length > 1 ? 1 : 0
        }
      },
      sort,
      highlight: {
        fields: {
          shippingPlanName: {}
        }
      }
    };
  }
  handleNegationCases(filter, mustNot) {
    const cleanFilter = filter.replace(/^\(NOT\s+|\)$/, "").trim();
    const [field, value] = this.parseFieldValue(cleanFilter);
    mustNot.push({
      term: {
        [field]: value
      }
    });
  }
  handleOrConditions(filter, must) {
    const cleanFilter = filter.replace(/^\(|\)$/, "").trim();
    const conditions = cleanFilter.split(/\sOR\s/).map(condition => condition.trim());
    const shouldConditions = conditions.map(condition => {
      if (condition.startsWith("(NOT")) {
        const negatedCondition = condition.replace(/^\(NOT\s+|\)$/, "").trim();
        const [field, value] = this.parseFieldValue(negatedCondition);
        return {
          bool: {
            must_not: [{
              term: {
                [field]: value
              }
            }]
          }
        };
      } else {
        const [field, value] = this.parseFieldValue(condition);
        return {
          term: {
            [field]: value
          }
        };
      }
    });
    must.push({
      bool: {
        should: shouldConditions,
        minimum_should_match: 1
      }
    });
  }
  handleSimpleConditions(filter, must) {
    const cleanFilter = filter.replace(/^\(|\)$/, "").trim();
    const [field, value] = this.parseFieldValue(cleanFilter);
    must.push({
      term: {
        [field]: value
      }
    });
  }
  parseFieldValue(condition) {
    const [field, value] = condition.split(":").map(part => part.trim());
    return [field, value.replace(")", "")];
  }
  isNumeric(str) {
    const validiNumericPattern = /^\d+$/;
    return validiNumericPattern.test(str);
  }
  parseAndSplitFilters(filters) {
    const result = [];
    filters.forEach(filter => {
      if (filter.includes("AND")) {
        const cleanFilter = filter.replace(/^\(|\)$/g, "").trim(); // Remove surrounding parentheses
        const splitFilters = cleanFilter.split(/\sAND\s/).map(part => part.trim()); // AND Should always be surrounded by spaces
        splitFilters.forEach(part => {
          // Enclose the part with "(" and ")" if not already enclosed
          if (!part.startsWith("(") && !part.endsWith(")")) {
            result.push(`(${part})`);
          } else {
            result.push(part);
          }
        });
      } else {
        result.push(filter);
      }
    });
    log.info({
      parseAndSplitFiltersResult: result
    }, "After Cleaning Filters on AND Clause And Splitting");
    return result.map(filter => this.convertBooleanFields(filter));
  }
  convertBooleanFields(filter) {
    BOOLEAN_ATTRIBUTES.forEach(field => {
      if (filter.includes(field)) {
        filter = filter.replace(`${field}:1`, `${field}:true`).replace(`${field}:0`, `${field}:false`);
      }
    });
    return filter;
  }
}