import { PrepType, SimplePrepType } from "@deliverr/prep-service-client";
import { createSelector } from "reselect";
import { selectPrepRequest } from "./selectPrepRequest";
import { selectFloorLoadedContainerDetails } from "./selectFloorLoadedContainerDetails";

/**
 * Determine if Prep exists and if it is chargeable on Seller Portal.
 */
export const selectHasChargeablePrep = createSelector(selectPrepRequest, selectFloorLoadedContainerDetails, (prepRequest, floorLoadedContainerDetails) => {
  if (floorLoadedContainerDetails) {
    return true;
  }
  if (!prepRequest) {
    return false;
  }
  if (prepRequest.prepTypes.includes(PrepType.FLOOR_LOADED_CONTAINER)) {
    return true;
  }
  if (prepRequest.prepTypes.includes(PrepType.BOX_CONTENT_LABELS)) {
    return true;
  }
  if (prepRequest.prepTypes.includes(PrepType.KITTING)) {
    return true;
  }
  if (Object.values(SimplePrepType).some(prepType => prepRequest.prepTypes.includes(prepType))) {
    return true;
  }
  return false;
});