import { OpenSearchService } from "common/search/services/OpenSearchService";
export class OrderIngestErrorService extends OpenSearchService {
  buildSearchOptions(request) {
    const searchOptions = super.buildSearchOptions(request);
    const boolShouldQueries = searchOptions?.query?.bool?.should ?? [];
    const shouldQueries = Array.isArray(boolShouldQueries) ? boolShouldQueries : [boolShouldQueries];
    if (request.searchTerm) {
      const searchTerm = request.searchTerm;
      shouldQueries.push({
        match: {
          "attributes.channelOrderId": `${searchTerm}`
        }
      });
    }
    const additionalSearchOptions = {
      query: {
        bool: {
          ...(searchOptions?.query?.bool ?? {}),
          should: shouldQueries,
          minimum_should_match: shouldQueries.length > 0 ? 1 : 0
        }
      }
    };
    if (this.config.searchConfig.highlightMatches && request.highlightMatches !== false) {
      additionalSearchOptions.highlight = {
        fields: {
          "attributes.channelOrderId": {}
        }
      };
      additionalSearchOptions.hydrate = true;
    }
    return {
      ...searchOptions,
      ...additionalSearchOptions
    };
  }
}