import { Channel, ErrorCode, ExternalDestinationType, WholesaleOrderStatus, WholesaleShipmentStatus, WholesaleShipmentType, WholesaleShippingMethod } from "@deliverr/wholesale-client";
import { isFbaIntegration } from "../../../transfers/utils/fbaIntegration";
import { mapWholesaleOrderStatusUiConfigs } from "./mapWholesaleOrderStatusUiConfigs";
import { mapWholesaleShipmentStatusUiConfigs } from "./mapWholesaleShipmentStatusUiConfigs";
import { extractFbaShipmentId } from "../../../transfers/utils/extractFbaShipmentId";
import { isEmpty } from "lodash";
import { addressRequiredFieldsValidation, isInternationalAddress } from "common/AddressUtils";
import { isValidFreightPhoneNumber } from "common/forms/PhoneUtils";
export const shouldShowShipmentInstructions = (shipment, wholesaleOrder) => {
  return !isFbaIntegration(wholesaleOrder) && shipment.carrierInfo?.shippingMethod === WholesaleShippingMethod.SELLER && shipment.shipmentType === WholesaleShipmentType.PARTIAL_PALLET && [WholesaleShipmentStatus.SUBMITTED, WholesaleShipmentStatus.PICKING_STARTED].includes(shipment.status);
};
export const shouldShowBookFreight = (shipment, wholesaleOrder) => {
  return !isFbaIntegration(wholesaleOrder) && shipment.carrierInfo?.shippingMethod === WholesaleShippingMethod.SELLER && shipment.shipmentType === WholesaleShipmentType.PARTIAL_PALLET && shipment.status === WholesaleShipmentStatus.READY_TO_SHIP;
};
export function getIsDeliverrFreight(wholesaleOrder, wholesaleShipment) {
  if (!wholesaleOrder || !wholesaleShipment && isEmpty(wholesaleOrder.shipments)) {
    return false;
  }
  const shipment = wholesaleShipment ?? wholesaleOrder.shipments[0]; // shipment type and method will be equal for all shipments on the order
  return shipment?.carrierInfo?.shippingMethod === WholesaleShippingMethod.DELIVERR && shipment?.shipmentType === WholesaleShipmentType.PARTIAL_PALLET;
}
export function getIsAmazonFreight(wholesaleOrder) {
  return !!(wholesaleOrder?.shipments?.some(shipment => shipment.carrierInfo?.shippingMethod === "SELLER") && isFbaIntegration(wholesaleOrder));
}
export function getIsShippingDeliverr(wholesaleOrder, wholesaleShipment) {
  if (!wholesaleOrder || !wholesaleShipment && isEmpty(wholesaleOrder.shipments)) {
    return false;
  }
  const shipment = wholesaleShipment ?? wholesaleOrder.shipments[0]; // shipment type and method will be equal for all shipments on the order
  return shipment?.carrierInfo?.shippingMethod === WholesaleShippingMethod.DELIVERR;
}
export function getShipmentType(wholesaleOrder, wholesaleShipment) {
  if (!wholesaleOrder || !wholesaleShipment && isEmpty(wholesaleOrder.shipments)) {
    return undefined;
  }
  const shipment = wholesaleShipment ?? wholesaleOrder.shipments[0]; // shipment type and method will be equal for all shipments on the order
  return shipment?.shipmentType ?? undefined;
}
export const shouldShowAmazonReferenceId = (wholesaleOrder, wholesaleShipment) => {
  return isFbaIntegration(wholesaleOrder) && wholesaleOrder.status !== WholesaleOrderStatus.DRAFT && wholesaleOrder.status !== WholesaleOrderStatus.CANCELLED && getIsDeliverrFreight(wholesaleOrder, wholesaleShipment);
};
export function checkIsCancellable(wholesaleOrder) {
  return !!(wholesaleOrder.status && mapWholesaleOrderStatusUiConfigs[wholesaleOrder.status]?.isCancellable && wholesaleOrder.shipments?.find(shipment => shipment.status && mapWholesaleShipmentStatusUiConfigs[shipment.status]?.isCancellable) && wholesaleOrder.externalDestinationType !== ExternalDestinationType.TRANSFER_ECOM);
}
export function extractShipmentExternalId(wholesaleShipment, wholesaleOrder) {
  return isFbaIntegration(wholesaleOrder) // for fba integration, we ignore the order external id and use the shipment's id if exists
  ? extractFbaShipmentId(wholesaleShipment) : wholesaleOrder.externalId;
}
export const isAddressIncomplete = address => {
  const requiredFields = isInternationalAddress(address) ? ["company", "street1", "city", "country"] : ["company", "street1", "city", "state", "country", "zip"];
  return addressRequiredFieldsValidation(address, requiredFields).filter(field => field !== "name").length > 0 || !safeIsValidFreightPhoneNumber(address.phone);
};
export const safeIsValidFreightPhoneNumber = phoneNumber => {
  try {
    return isValidFreightPhoneNumber(phoneNumber ?? "");
  } catch (e) {
    return false;
  }
};
export function isDraftSPSOrderWithOOSError(wholesaleOrder) {
  return !!(wholesaleOrder.status && mapWholesaleOrderStatusUiConfigs[wholesaleOrder.status]?.allowSubmit && wholesaleOrder.shipments?.find(shipment => shipment.status === WholesaleShipmentStatus.DRAFT) && wholesaleOrder.channel && SPS_CHANNELS.includes(wholesaleOrder.channel) && wholesaleOrder.errors?.includes(ErrorCode.OUT_OF_STOCK));
}
export const SPS_CHANNELS = Object.freeze([Channel.SPS, Channel.SPS_TEST]);
export const isValidCompany = company => {
  return !!company && company.length >= 3;
};