import { ServiceLevel } from "@deliverr/billing-pricer-client";
import { BoxDimension } from "common/components/EstimatedCosts/BoxDimensions";
import { CostEstimatorModalActionTypes } from "common/components/EstimatedCosts/CostEstimatorModalActions";
import { createReducer } from "common/ReduxUtils";
export const costEstimatorInitialState = {
  allInputsValid: false,
  dimensions: {},
  showNewPricing: false,
  hazmatInfo: [],
  shippingSpec: undefined,
  serviceLevel: ServiceLevel.StandardNonFastTag
};
export const allDimensionsValid = dimensions => Object.values(BoxDimension).every(dimension => !isNaN(dimensions[dimension]) && dimensions[dimension] > 0);
const reducers = {
  [CostEstimatorModalActionTypes.SET_COST_ESTIMATOR_PRODUCT]: (state, {
    dsku,
    productName,
    dimensions,
    hazmatInfo,
    shippingSpec,
    serviceLevel,
    countryCode
  }) => {
    return {
      ...state,
      dsku,
      productName,
      dimensions,
      allInputsValid: allDimensionsValid(dimensions),
      hazmatInfo,
      shippingSpec,
      serviceLevel,
      countryCode
    };
  },
  [CostEstimatorModalActionTypes.SET_FIELD_VALUE]: (state, {
    field,
    value
  }) => {
    const dimensions = {
      ...state.dimensions,
      [field]: value
    };
    return {
      ...state,
      dimensions,
      allInputsValid: allDimensionsValid(dimensions)
    };
  },
  [CostEstimatorModalActionTypes.SET_SHOW_NEW_PRICING]: (state, {
    showNewPricing
  }) => ({
    ...state,
    showNewPricing
  })
};
export const costEstimatorReducer = createReducer(costEstimatorInitialState, reducers);