import { AttachmentNonComplianceType, AttachmentType, NonComplianceStatus, NonComplianceType } from "@deliverr/replenishment-client";
import { defineMessages } from "react-intl";
const replenishmentNonComplianceTypeTitle = {
  [NonComplianceType.LABEL_NON_COMPLIANCE]: {
    id: "replenishment.nonCompliance.type.incorrectLabels",
    defaultMessage: "Incorrect Labels"
  }
};
const replenishmentNonComplianceStatusDescription = {
  [NonComplianceStatus.CANCELLED]: {
    id: "replenishment.nonCompliance.status.cancelled",
    defaultMessage: "Cancelled"
  },
  [NonComplianceStatus.CREATED]: {
    id: "replenishment.nonCompliance.status.actionRequired",
    defaultMessage: "Action Required"
  },
  [NonComplianceStatus.PENDING_VERIFICATION]: {
    id: "replenishment.nonCompliance.status.withSupport",
    defaultMessage: "With Support"
  },
  [NonComplianceStatus.RESOLVED]: {
    id: "replenishment.nonCompliance.status.resolved",
    defaultMessage: "Resolved"
  },
  UNKNOWN: {
    id: "replenishment.nonCompliance.status.unknown",
    defaultMessage: "Unknown"
  }
};
export const ReplenishmentNonComplianceLabels = {
  types: {
    ...defineMessages(replenishmentNonComplianceTypeTitle)
  },
  statuses: {
    ...defineMessages(replenishmentNonComplianceStatusDescription)
  },
  details: {
    ...defineMessages({
      title: {
        id: "replenishment.details.nonCompliance.table.title",
        defaultMessage: "Order Problems"
      }
    })
  },
  blade: {
    ...defineMessages({
      orderId: {
        id: "replenishment.nonCompliance.blade.details.orderId",
        defaultMessage: "Order ID: {orderId}"
      },
      shipmentId: {
        id: "replenishment.nonCompliance.blade.details.shipmentId",
        defaultMessage: "Shipment ID: {shipmentId}"
      },
      poNumber: {
        id: "replenishment.nonCompliance.blade.details.poNumber",
        defaultMessage: "PO#: {poNumber}"
      },
      caseCount: {
        id: "replenishment.nonCompliance.blade.details.caseCount",
        defaultMessage: "# Cases: {caseCount}"
      },
      palletCount: {
        id: "replenishment.nonCompliance.blade.details.palletCount",
        defaultMessage: "# Pallets: {palletCount}"
      },
      submit: {
        id: "replenishment.nonCompliance.blade.submit",
        defaultMessage: "Submit"
      },
      loading: {
        id: "replenishment.nonCompliance.blade.loading",
        defaultMessage: "Loading..."
      },
      needHelp: {
        id: "replenishment.nonCompliance.blade.needHelp",
        defaultMessage: "Need help? {contactSupport}"
      },
      contactSupport: {
        id: "replenishment.nonCompliance.blade.contactSupport",
        defaultMessage: "Contact support"
      },
      orderProblems: {
        id: "replenishment.nonCompliance.blade.orderProblems",
        defaultMessage: "{learnMore} about order problems."
      }
    })
  }
};
export const ReplenishmentLabelNonComplianceMessages = defineMessages({
  uploadedLabels: {
    id: "replenishment.nonCompliance.label.uploadedLabels",
    defaultMessage: "Uploaded labels"
  },
  reuploadLabels: {
    id: "replenishment.nonCompliance.label.reuploadLabels",
    defaultMessage: "Re-upload labels"
  },
  [AttachmentNonComplianceType.MISSING]: {
    id: "replenishment.nonCompliance.label.missingLabels",
    defaultMessage: "Insufficient labels"
  },
  [AttachmentNonComplianceType.CORRUPT]: {
    id: "replenishment.nonCompliance.label.corruptLabelsDescription",
    defaultMessage: "Labels not readable"
  },
  previouslyUploadedLabels: {
    id: "replenishment.nonCompliance.label.previouslyUploadedLabels",
    defaultMessage: "Previously uploaded labels"
  },
  downloadedUploadedLabels: {
    id: "replenishment.nonCompliance.label.downloadedUploadedLabels",
    defaultMessage: "Download uploaded labels"
  },
  downloadPreviousLabels: {
    id: "replenishment.nonCompliance.label.downloadPreviousLabels",
    defaultMessage: "Download previous labels"
  },
  reprocessLabelUploadsInstruction: {
    id: "replenishment.nonCompliance.label.reprocessLabelUploadsInstruction",
    defaultMessage: "Please re-upload above mentioned labels for us to process the order. Any delay in sharing required labels will lead to delay in shipping."
  },
  unableToProcessLabelsDescription: {
    id: "replenishment.nonCompliance.label.unableToProcessLabels",
    defaultMessage: "Our warehouse was unable to process the order due to problem with the labels uploaded by you."
  },
  caseCountMismatchNotification: {
    id: "replenishment.nonCompliance.label.caseCountMismatchNotification",
    defaultMessage: "Note: Count of labels should match case count."
  },
  palletCountMismatchNotification: {
    id: "replenishment.nonCompliance.label.palletCountMismatchNotification",
    defaultMessage: "Note: Count of labels should match pallet count."
  },
  createdAtDescription: {
    id: "replenishment.nonCompliance.label.caseCountMismatchNotification",
    defaultMessage: "Note: Count of labels should match case count."
  },
  submissionConfirmedDescription: {
    id: "replenishment.nonCompliance.label.submissionConfirmedDescription",
    defaultMessage: "Your order will be shipped as soon as possible. This might take up to 2 working days."
  },
  submissionRejectedDescription: {
    id: "replenishment.nonCompliance.label.submissionRejectedDescription",
    defaultMessage: "The uploaded labels were not complete. Kindly re-upload labels for case count mentioned below."
  },
  submissionReceivedTitle: {
    id: "replenishment.nonCompliance.label.submissionReceivedTitle",
    defaultMessage: "Submission Received"
  },
  submissionReceivedDescription: {
    id: "replenishment.nonCompliance.label.submissionReceivedDescription",
    defaultMessage: "This uploaded files are being manually reviewed by Flexport's operations team."
  },
  submissionConfirmedTitle: {
    id: "replenishment.nonCompliance.label.submissionConfirmedTitle",
    defaultMessage: "Submission Confirmed"
  },
  nonComplianceCancelledTitle: {
    id: "replenishment.nonCompliance.label.nonComplianceCancelledTitle",
    defaultMessage: "Non-Compliance Cancelled"
  },
  nonComplianceCancelledDescription: {
    id: "replenishment.nonCompliance.label.nonComplianceCancelledDescription",
    defaultMessage: "The non-compliance has been cancelled."
  },
  submissionRejectedTitle: {
    id: "replenishment.nonCompliance.label.submissionRejectedTitle",
    defaultMessage: "Submission Rejected"
  },
  submissionPending: {
    id: "replenishment.nonCompliance.label.submissionPending",
    defaultMessage: "Submission Pending"
  },
  [AttachmentType.BOL]: {
    id: "replenishment.nonCompliance.label.bol",
    defaultMessage: "BOL"
  },
  [AttachmentType.CASE_LABELS]: {
    id: "replenishment.nonCompliance.label.caseLabel",
    defaultMessage: "Case Labels"
  },
  [AttachmentType.PALLET_LABELS]: {
    id: "replenishment.nonCompliance.label.palletLabel",
    defaultMessage: "Pallet Labels"
  },
  [AttachmentType.SHIPPING_LABELS]: {
    id: "replenishment.nonCompliance.label.shippingLabels",
    defaultMessage: "Shipping Labels"
  },
  [AttachmentType.COMMERCIAL_INVOICE]: {
    id: "replenishment.nonCompliance.label.commercialInvoice",
    defaultMessage: "Commercial invoice (for international shipments)"
  }
});