// determines order of steps in the seller inbound flow
export let InboundStep;
(function (InboundStep) {
  InboundStep["SELECT_INBOUND_TYPE"] = "SELECT_INBOUND_TYPE";
  InboundStep["SELECT_PRODUCTS"] = "SELECT_PRODUCTS";
  InboundStep["SHIPPING_PLAN_PRODUCTS"] = "SHIPPING_PLAN_PRODUCTS";
  InboundStep["ASSIGN_CATEGORIES"] = "ASSIGN_CATEGORIES";
  InboundStep["DELIVERR_PREP"] = "DELIVERR_PREP";
  InboundStep["LOT_INFORMATION"] = "LOT_INFORMATION";
  InboundStep["PACKAGING_REQUIREMENTS"] = "PACKAGING_REQUIREMENTS";
  InboundStep["CUSTOMS"] = "CUSTOMS";
  InboundStep["BARCODE_INPUT"] = "BARCODE_INPUT";
  InboundStep["BARCODE_PRINT"] = "BARCODE_PRINT";
  InboundStep["FROM_ADDRESS"] = "FROM_ADDRESS";
  InboundStep["DISTRIBUTE_SELECT"] = "DISTRIBUTE_SELECT";
  InboundStep["ADD_DIMENSIONS"] = "ADD_DIMENSIONS";
  InboundStep["SHIP"] = "SHIP";
  InboundStep["SHIPMENT_CONFIRMED"] = "SHIPMENT_CONFIRMED";
})(InboundStep || (InboundStep = {}));