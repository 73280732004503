import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
export class ClaimClient {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.CLAIM_CLIENT_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL
    });
  }
  async createClaim(claim) {
    return await this.apiClient.post({
      url: "/claims/create",
      body: claim,
      authentication: isAuthenticated
    });
  }
  async getClaimsByFilter(filter) {
    return await this.apiClient.get({
      url: "/claims/claims",
      params: filter,
      authentication: isAuthenticated
    });
  }
  async indexFile(contentType, title, sellerId) {
    return await this.apiClient.post({
      url: "/claims/index-file",
      body: {
        contentType,
        title,
        sellerId
      },
      authentication: isAuthenticated
    });
  }
  async approveClaim(claimId, by, comment) {
    const baseURL = `/claims/approve?claimId=${claimId}&by=${by}`;
    return await this.apiClient.put({
      url: comment ? `${baseURL}&comment=${encodeURIComponent(comment)}` : baseURL,
      authentication: isAuthenticated
    });
  }
  async approveRevisedClaim(claimId, by, revisedAmount, comment) {
    const baseURL = `/claims/approve-revised?claimId=${claimId}&by=${by}&revisedAmount=${revisedAmount}`;
    return await this.apiClient.put({
      url: comment ? `${baseURL}&comment=${encodeURIComponent(comment)}` : baseURL,
      authentication: isAuthenticated
    });
  }
  async rejectClaim(claimId, by, rejectionReason, comment) {
    const body = {
      by,
      claimId,
      rejectionReason
    };
    return await this.apiClient.put({
      url: "/claims/reject",
      body: comment ? {
        ...body,
        comment
      } : body,
      authentication: isAuthenticated
    });
  }
  async retractClaim(claimId, by, retractionReason) {
    return await this.apiClient.put({
      url: "/claims/retract",
      body: {
        by,
        claimId,
        retractionReason
      },
      authentication: isAuthenticated
    });
  }
  async uploadUrl(contentType, title, sellerId, index) {
    return await this.apiClient.post({
      url: "/claims/upload-url",
      params: {
        index
      },
      body: {
        contentType,
        title,
        sellerId
      },
      authentication: isAuthenticated
    });
  }
}