import { ApiError } from "@deliverr/commons-objects";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { batch } from "react-redux";
import { searchServiceFor } from "common/search/Factory";
import { ListType } from "common/list";
import { WholesaleListActionTypes } from "../actions/WholesaleListActionTypes";
import { getListItemsSuccessAction, wholesaleListError } from "../actions/WholesaleListAction";
import { getShowAdminFeatures } from "common/user/UserSelectors";
import { selectWholesaleList } from "../store/selectWholesaleList";
import { selectWholesaleListPageSize } from "../store/selectWholesaleListPageSize";
import { FeatureName, getFeatureSelector } from "common/Split";
import { constructWholesaleListOpenSearchFilters } from "./constructWholesaleListOpensearchFilters";
const reserveStorageOutboundsSearchService = searchServiceFor(ListType.ReserveStorageOutbounds);
export const searchWholesaleOrders = () => async (dispatch, getState) => {
  batch(() => {
    dispatch(addLoader(WholesaleListActionTypes.WHOLESALE_LIST_GET_LOADING));
    dispatch(wholesaleListError(false));
  });
  const shouldShowAdminFeatures = getShowAdminFeatures(getState());
  try {
    const {
      searchTerm,
      pageNum,
      searchFilters,
      sort
    } = selectWholesaleList(getState());
    const pageSize = selectWholesaleListPageSize(getState());
    const isWholesaleOrderHeldStateOn = getFeatureSelector(FeatureName.WholesaleOrderHeldState)(getState());
    const result = await reserveStorageOutboundsSearchService.execute({
      page: pageNum,
      sort,
      customizedOpenSearchFilters: constructWholesaleListOpenSearchFilters(searchTerm, searchFilters, isWholesaleOrderHeldStateOn, shouldShowAdminFeatures),
      pageSize
    });
    const pageItems = result.hits;
    const nbPages = result.response.nbPages;
    dispatch(getListItemsSuccessAction({
      pageItems,
      nbPages,
      pageNum
    }));
  } catch (err) {
    if (err.message !== ApiError.UNKNOWN_ERROR) {
      dispatch(wholesaleListError(true));
    }
  } finally {
    dispatch(clearLoader(WholesaleListActionTypes.WHOLESALE_LIST_GET_LOADING));
  }
};