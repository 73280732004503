import { useState, useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useAlgoliaService } from "common/hooks/useAlgoliaService";
import { debounce } from "lodash/fp";
import { carrierCodeToOption, CARRIER_INPUT_DEBOUNCE_INTERVAL, CARRIER_SELECT_ALGOLIA_CONFIG, lookupCarrier, processSearchResultsToOptions } from "./CarrierSelectUtil";
import { FeatureName, useFeatureOn } from "common/Split";
import { useLogisticsCarrierSearchService } from "./useLogisticsCarrierSearchService";
export const useCarrierSelect = selectOptions => {
  const isLogisticsSearchEnabled = useFeatureOn(FeatureName.LogisticsSearchUseCarrierSearch);
  const {
    handleInputChange,
    handleSelectionChange,
    carrier
  } = selectOptions ?? {};
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState(carrierCodeToOption(carrier));
  const setDebouncedTerm = debounce(CARRIER_INPUT_DEBOUNCE_INTERVAL, term => setSearchTerm(term));
  const {
    formatMessage
  } = useIntl();
  const {
    data
  } = useAlgoliaService({
    searchTerm,
    serviceConfig: CARRIER_SELECT_ALGOLIA_CONFIG,
    isAlgoliaSearchEnabled: !isLogisticsSearchEnabled
  });
  const {
    data: lookupData
  } = useAlgoliaService({
    searchTerm: carrier ?? "",
    serviceConfig: CARRIER_SELECT_ALGOLIA_CONFIG,
    isAlgoliaSearchEnabled: !isLogisticsSearchEnabled
  });
  const {
    data: carrierOpenSearchData
  } = useLogisticsCarrierSearchService({
    searchTerm,
    isLogisticsSearchEnabled
  });
  const {
    data: carrierOpenSearchLookupData
  } = useLogisticsCarrierSearchService({
    searchTerm: carrier ?? "",
    isLogisticsSearchEnabled
  });
  useEffect(() => {
    setSelectedOption(lookupCarrier(carrier)(isLogisticsSearchEnabled ? carrierOpenSearchLookupData : lookupData));
  }, [carrier, carrierOpenSearchLookupData, isLogisticsSearchEnabled, lookupData]);

  // labels
  const placeholder = formatMessage({
    id: "CarrierSearchBar.placeholder",
    defaultMessage: "Enter your carrier"
  });
  const handleSearch = useCallback(searchText => {
    handleInputChange?.(searchText);
    setDebouncedTerm(searchText);
    return searchText;
  }, [handleInputChange, setDebouncedTerm]);
  const onChange = option => {
    handleSelectionChange?.(option?.value);
    setSelectedOption(option);
  };
  return {
    handleSearch,
    selectedOption,
    setSelectedOption,
    searchTerm,
    placeholder,
    onChange,
    options: processSearchResultsToOptions(isLogisticsSearchEnabled ? carrierOpenSearchData : data)
  };
};