import { defineMessages } from "react-intl";
import { WholesaleIngestionChannel } from "./store/WholesaleListState";
export const wholesaleListIngestionChannelFilterLabels = defineMessages({
  [WholesaleIngestionChannel.ALL]: {
    id: "wholesale.list.ingestionChannelFilter.all",
    defaultMessage: "All Sources"
  },
  [WholesaleIngestionChannel.SPS]: {
    id: "wholesale.list.ingestionChannelFilter.sps",
    defaultMessage: "SPS"
  },
  [WholesaleIngestionChannel.OTHERS]: {
    id: "wholesale.list.ingestionChannelFilter.others",
    defaultMessage: "Others"
  }
});