import { ServiceLevel } from "@deliverr/billing-pricer-client";
import { Destination } from "@deliverr/commons-objects";
const destinationsByService = {
  [ServiceLevel.WorldwideStandard]: Destination.INTERNATIONAL,
  [ServiceLevel.WorldwidePartnerDeliveredDutyPaid]: Destination.INTERNATIONAL,
  [ServiceLevel.WorldwidePartnerDeliveredDutyUnpaid]: Destination.INTERNATIONAL,
  [ServiceLevel.WorldwideStandardDeliveredDutyUnpaid]: Destination.INTERNATIONAL,
  [ServiceLevel.AKHIOther]: Destination.NON_CONTIGUOUS_US,
  [ServiceLevel.AKHIOtherNonFastTag]: Destination.NON_CONTIGUOUS_US,
  [ServiceLevel.Military]: Destination.NON_CONTIGUOUS_US
};
export const destinationByService = serviceLevel => destinationsByService[serviceLevel] || Destination.CONTIGUOUS_US;