import { batch } from "react-redux";
import { createActionCreator } from "common/ReduxUtils";
import { WholesaleListActionTypes } from "./WholesaleListActionTypes";
export const getListItemsSuccessAction = createActionCreator(WholesaleListActionTypes.WHOLESALE_LIST_GET_SUCCESS, "listData");
export const wholesaleListError = createActionCreator(WholesaleListActionTypes.WHOLESALE_LIST_GET_ERROR, "isError");
export const wholesaleListChangePageNum = createActionCreator(WholesaleListActionTypes.WHOLESALE_LIST_CHANGE_PAGE_NUM, "pageNum");
export const setWholesaleListTabFilter = tabFilter => async dispatch => batch(() => {
  dispatch({
    type: WholesaleListActionTypes.WHOLESALE_LIST_SET_TAB_FILTER,
    tabFilter
  });
  dispatch(wholesaleListChangePageNum(0));
});
export const wholesalesListChangeSearchTerm = createActionCreator(WholesaleListActionTypes.WHOLESALE_LIST_CHANGE_SEARCH_TERM, "searchTerm");
export const wholesaleListChangeStatusFilter = createActionCreator(WholesaleListActionTypes.WHOLESALE_LIST_CHANGE_STATUS_FILTERS, "statuses");
export const wholesaleListChangeSourceTypeFilter = createActionCreator(WholesaleListActionTypes.WHOLESALE_LIST_CHANGE_INGESTION_CHANNEL_FILTERS, "ingestionChannel");