import { ListType } from "common/list";
import { AlgoliaSearchService } from "common/search/services/AlgoliaSearchService";
import { configFor } from "./services/SearchConfig";
import { InventorySearchService } from "common/search/services/InventorySearchService";
import { memoize } from "lodash";
import { OrderIngestErrorService } from "./services/OrderIngestErrorService";
import { OrderSearchService } from "./services/OrderSearchService";
import { ParcelSearchService } from "common/search/services/ParcelSearchService";
import { PrepSearchService } from "./services/PrepSearchService";
import { ClaimUploadsSearchService } from "./services/ClaimUploadsSearchService";
import { BundleOpenSearchService } from "./services/BundleOpenSearchService";
import { ReserveStorageOutboundsSearchService } from "./services/ReserveStorageOutboundsSearchService";
import { InboundsSearchService } from "./services/InboundsSearchService";
import { CarrierSearchService } from "./services/CarrierSearchService";
export const searchServiceFor = memoize(listType => {
  switch (listType) {
    case ListType.Inventory:
    case ListType.Bundle:
      return new AlgoliaSearchService(configFor(listType));
    case ListType.BundleV2:
      return new BundleOpenSearchService(configFor(listType));
    case ListType.InventoryV2:
      return new InventorySearchService(configFor(listType));
    case ListType.Parcel:
      return new ParcelSearchService(configFor(listType));
    case ListType.Prep:
      return new PrepSearchService(configFor(listType));
    case ListType.ClaimUploads:
      return new ClaimUploadsSearchService(configFor(listType));
    case ListType.ReserveStorageOutbounds:
      return new ReserveStorageOutboundsSearchService(configFor(listType));
    case ListType.InboundListV2:
      return new InboundsSearchService(configFor(listType));
    case ListType.Carriers:
      return new CarrierSearchService(configFor(listType));
    case ListType.Orders:
      return new OrderSearchService(configFor(listType));
    case ListType.OrderIngestErrors:
      return new OrderIngestErrorService(configFor(listType));
    case ListType.Inbounds:
    default:
      return new AlgoliaSearchService(configFor(listType));
  }
});