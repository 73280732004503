// value should correspond exactly to what appears in splitio
// remember to remove references to and delete splits that have been rolled out to 100%
export let FeatureName;

/**
 * Splits that should be treated as "on" if the split client is not available
 */
(function (FeatureName) {
  FeatureName["BrandedPackaging"] = "branded_packaging";
  FeatureName["BrandedPackagingDskuSelection"] = "branded_packaging_dsku_selection";
  FeatureName["BrandedPackagingOmitPricing"] = "BRANDED_PACKAGING_OMIT_PRICING";
  FeatureName["BrandedPackagingInboundCreation"] = "branded_packaging_inbound_creation";
  FeatureName["BulkCreateProductsFeature"] = "BULK_CREATE_PRODUCTS_FEATURE";
  FeatureName["BulkUploadV2"] = "bulk_upload_v2";
  FeatureName["B2BViaOpenSearch"] = "B2B_VIA_OPENSEARCH";
  FeatureName["ClaimsIncorrectMissingProducts"] = "claims_incorrect_missing_products";
  FeatureName["ClaimsUploadSearch"] = "claims_upload_search";
  FeatureName["CrossdockChargeExempt"] = "charge_for_crossdock";
  FeatureName["CustomBarcodes"] = "SP_CREATE_CUSTOM_BARCODES";
  FeatureName["DashboardDiscrepantUnits"] = "dashboard_discrepant_units";
  FeatureName["DDPMarketsPro"] = "DDP_MarketsPro";
  FeatureName["EcomPrep"] = "ECOM_PREP_ENABLED";
  FeatureName["FbaBulkProductCSVUpload"] = "FBA_BULK_PRODUCT_CSV_UPLOAD";
  FeatureName["FefoExpirationDate"] = "fefo_expiration_date";
  FeatureName["GlobalStopShipByDays"] = "stop_ship_by_days";
  FeatureName["SkuStopShipByDays"] = "sku_stop_ship_by_days";
  FeatureName["ForethoughtChatWidget"] = "SP_FORETHOUGHT_CHAT_WIDGET";
  FeatureName["FreightConsolidatedLTL"] = "SP_FREIGHT_CONSOLIDATED_LTL";
  FeatureName["FreightDrayage"] = "SP_FREIGHT_DRAYAGE";
  FeatureName["FreightRatesPreview"] = "SP_FREIGHT_RATES_PREVIEW";
  FeatureName["FreightSelfServe"] = "SP_FREIGHT";
  FeatureName["Hazmat3480And3090Expansion"] = "hazmat_3480_and_3090_expansion_seller_portal";
  FeatureName["HideForwardingCost"] = "HIDE_FORWARDING_COST";
  FeatureName["InboundExpectedQtyUneditable"] = "SP_INBOUND_EXPECTED_QTY_UNEDITABLE";
  FeatureName["InventoryAdjustmentHistory"] = "inventory_adjustment_history";
  FeatureName["InventoryLedger"] = "inventory_ledger";
  FeatureName["InventoryLedgerSourcedReports"] = "inventory_ledger_sourced_reports";
  FeatureName["LegacySfnOnboarding"] = "LEGACY_SFN_ONBOARDING";
  FeatureName["LotBulkActions"] = "lot_bulk_actions";
  FeatureName["LotQuarantinedRemovalDisposal"] = "lot_quarantined_removal_disposal";
  FeatureName["LotTrackingTableV2"] = "lot_tracking_table_v2";
  FeatureName["LotUnquarantinedRemovalDisposal"] = "lot_unquarantined_removal_disposal";
  FeatureName["ManualOrderCreation"] = "manual_order_creation";
  FeatureName["MetroDeliverr"] = "metro_deliverr";
  FeatureName["Notifications"] = "notifications";
  FeatureName["OneToManyViewing"] = "one_to_many_viewing";
  FeatureName["OrderWarehouseAlert"] = "order_warehouse_alert";
  FeatureName["PeakClaimsWindow"] = "peak_claims_window";
  FeatureName["PeakSeason2024Banner"] = "PEAK_SEASON_2024_BANNER";
  FeatureName["PrepKittingEnabled"] = "prep_kitting_enabled";
  FeatureName["PrepKittingInbounds"] = "PREP_KITTING_INBOUNDS";
  FeatureName["PrepPalletLabelsEnabled"] = "PREP_PALLET_LABELS_ENABLED";
  FeatureName["PrepDetailsPageEnabled"] = "PREP_DETAILS_PAGE";
  FeatureName["PrimarySalesChannelBanner"] = "PRIMARY_SALES_CHANNEL_BANNER";
  FeatureName["ReserveStorageLotTracking"] = "RESERVE_STORAGE_LOT_TRACKING";
  FeatureName["ReturnsRTM"] = "returns_rtm";
  FeatureName["ReturnsRtv"] = "returns_rtv";
  FeatureName["SaveStorageInboundTrackingInfo"] = "SAVE_STORAGE_INBOUND_TRACKING_INFO";
  FeatureName["ShopifyExperimentAbTest"] = "shopify_experiment_ab_test";
  FeatureName["ShopifyPaidInternationalBadges"] = "shopify_paid_international_badges";
  FeatureName["ShowActualCostInSummary"] = "SHOW_ACTUAL_COST_IN_SUMMARY";
  FeatureName["SkipAddingLotExpiry"] = "skip_adding_lot_expiry";
  FeatureName["LogisticsSearchInventoryListing"] = "LOGISTICS_SEARCH_INVENTORY_LISTING";
  FeatureName["LogisticsSearchInventoryDetail"] = "LOGISTICS_SEARCH_INVENTORY_DETAIL";
  FeatureName["LogisticsSearchOrderListing"] = "LOGISTICS_SEARCH_ORDER_LISTING";
  FeatureName["BillingNewFulfillmentRates"] = "BILLING_NEW_FULFILLMENT_RATES";
  FeatureName["WholesaleViewV2"] = "WHOLESALE_VIEW_V2";
  FeatureName["ZendeskChat"] = "ZENDESK_CHAT";
  FeatureName["ChatIntegration"] = "CHAT_INTEGRATION";
  FeatureName["AllowLargerProductsInternationally"] = "ALLOW_PRODUCTS_EXCEEDING_20_LBS_INTERNATIONALLY";
  FeatureName["SmbAccelerate"] = "SP_SMB_ACCELERATE";
  FeatureName["SmbAccelerateOnboardingBannerFix"] = "SP_SMB_ACCELERATE_ONBOARDING_BANNER_FIX";
  FeatureName["SmbAccelerateIpb"] = "SP_SMB_ACCELERATE_IPB";
  FeatureName["SmbAccelerateShipEx"] = "SP_SMB_ACCELERATE_SHIPEX";
  FeatureName["SmbAccelerateIfo"] = "SP_SMB_ACCELERATE_IFO";
  FeatureName["SmbAccelerateNotifications"] = "SP_SMB_ACCELERATE_NOTIFICATIONS";
  FeatureName["SmbAccelerateFeedback"] = "SP_SMB_ACCELERATE_FEEDBACK";
  FeatureName["SmbAccelerateRelayTest"] = "SP_SMB_ACCELERATE_RELAY_TEST";
  FeatureName["SmbAccelerateFlexportPlus"] = "SP_SMB_ACCELERATE_FP_PLUS";
  FeatureName["SmbAccelerateFbaAutomated"] = "SP_SMB_ACCELERATE_FBA_AUTOMATED";
  FeatureName["SmbAccelerateFbaUnitPrep"] = "SP_SMB_ACCELERATE_FBA_UNIT_PREP";
  FeatureName["SmbAccelerateShowInsuranceInCreateShipment"] = "SP_SMB_SHOW_INSURANCE_IN_CREATE_SHIPMENT";
  FeatureName["SmbAccelerateCustomsClearanceInFreightCard"] = "SP_SMB_CUSTOMS_CLEARANCE_IN_FREIGHT_CARD";
  FeatureName["SmbAccelerateBookingStatus"] = "SP_SMB_BOOKING_STATUS";
  FeatureName["SmbAccelerateRevampedOriginSearch"] = "SP_SMB_ACCELERATE_REVAMPED_ORIGIN_SEARCH";
  FeatureName["SmbForeignImportOnboardingFlow"] = "SMB_FOREIGN_IMPORT_ONBOARDING_FLOW";
  FeatureName["SmbAccelerateAccountAutoLink"] = "SP_SMB_ACCELERATE_ACCOUNT_AUTO_LINK";
  FeatureName["SmbInsuranceProfile"] = "SP_SMB_INSURANCE_PROFILE";
  FeatureName["SmbIpbDevTools"] = "SMB_IPB_DEVTOOLS";
  FeatureName["SmbIpbDrafts"] = "SMB_IPB_DRAFTS";
  FeatureName["SmbAccelerateLithiumBatteriesWorkflow"] = "SP_SMB_LITHIUM_BATTERIES_WORKFLOW";
  FeatureName["SmbOfferingTrace"] = "SMB_OFFERING_TRACE";
  FeatureName["SmbOnboardingUpdateEinUi"] = "SP_SMB_ONBOARDING_UPDATE_EIN_UI";
  FeatureName["SmbPostBookingQuote"] = "SP_SMB_POST_BOOKING_QUOTES";
  FeatureName["SmbShipmentProblems"] = "SP_SMB_SHIPMENT_PROBLEMS";
  FeatureName["SmbCreditCardFee"] = "charge_credit_card_processing_fee";
  FeatureName["SmbPackagesBulkUpload"] = "SMB_PACKAGES_BULK_UPLOAD";
  FeatureName["StorageAdminBarcodeDownload"] = "storage_admin_barcode_download";
  FeatureName["StorageMultiCasePack"] = "reserve_storage_multi_case_pack";
  FeatureName["UnifiedPricingNewMerchant"] = "UNIFIED_PRICING_NEW_MERCHANT";
  FeatureName["UnpaidInvoice"] = "unpaid_invoice";
  FeatureName["UserContactAreas"] = "user_contact_areas";
  FeatureName["WholesaleDuplicateOrder"] = "wholesale_duplicate_order";
  FeatureName["WholesaleForbid"] = "wholesale_forbid";
  FeatureName["WholesaleOrderHeldState"] = "WHOLESALE_ORDER_HELD_STATE";
  FeatureName["WholesaleOrderEachPicking"] = "WHOLESALE_ORDER_EACH_PICKING";
  FeatureName["WholesaleServiceSyncAPI"] = "WHOLESALE_SERVICE_SYNC_API";
  FeatureName["wfsReplenishmentSettings"] = "WFS_REPLENISHMENT_SETTINGS";
  FeatureName["FbaMultiShipmentShipping"] = "FBA_MULTI_SHIPMENT_SHIPPING";
  FeatureName["ParcelApiLimitedAccess"] = "PARCEL_API_LIMITED_ACCESS";
  FeatureName["ParcelLinkFlexportShipment"] = "PARCEL_LINK_FLEXPORT_SHIPMENT";
  FeatureName["ParcelValidateLinkFlexportShipment"] = "PARCEL_VALIDATE_LINK_FLEXPORT_SHIPMENT";
  FeatureName["TransferDetailsPageViaReplenishmentService"] = "transfer_details_page_via_replenishment_service";
  FeatureName["ReplenishmentsLotBasedOutbounds"] = "REPLENISHMENTS_LOT_BASED_OUTBOUNDS";
  FeatureName["FbaUnitPrep"] = "FBA_UNIT_PREP";
  FeatureName["SmbIpbIntlUnavailable"] = "SMB_IPB_INTL_UNAVAILABLE";
  FeatureName["WarehouseRemoval"] = "SP_WAREHOUSE_REMOVAL";
  FeatureName["WholesaleEachPicking"] = "wholesale_each_picking";
  FeatureName["TellMeAboutYourBusinessRevamp"] = "TELL_ME_ABOUT_YOUR_BUSINESS_REVAMP";
  FeatureName["GriLaunch"] = "GRI_LAUNCH";
  FeatureName["ReserveStorageInboundReplenishmentRecommendations"] = "RESERVE_STORAGE_INBOUND_REPLENISHMENT_RECOMMENDATIONS";
  FeatureName["SmbIpbDtc"] = "SMB_IPB_DTC";
  FeatureName["WarehouseMoveBanner"] = "WAREHOUSE_MOVE_BANNER";
  FeatureName["SmbIpbBookingV2"] = "SMB_IPB_BOOKING_V2";
  FeatureName["LtlQuoteImprovement"] = "LTL_QUOTE_IMPROVEMENT";
  FeatureName["UnifiedSFNMigration"] = "UNIFIED_SFN_MIGRATION";
  FeatureName["IntlInboundRsSelection"] = "INTL_INBOUND_RS_SELECTION";
  FeatureName["InboundRsDimRelaxation"] = "INBOUND_RS_DIM_RELAXATION";
  FeatureName["BulkCancelOrderFeature"] = "BULK_CANCEL_ORDER_FEATURE";
  FeatureName["LogisticsSearchUseLinkProduct"] = "logistics_search_useLinkProduct";
  FeatureName["LogisticsSearchUseProductSearch"] = "logistics_search_useProductSearch";
  FeatureName["LogisticsSearchFetchProductsByDskus"] = "logistics_search_fetchProductsByDskus";
  FeatureName["DisableIntegrationEditNonAdmin"] = "DISABLE_INTEGRATION_EDIT_NON_ADMIN";
  FeatureName["BoxDimensionsRestrictionsRelaxed"] = "BOX_DIMENSIONS_RESTRICTIONS_RELAXED";
  FeatureName["LogisticsSearchShipmentRead"] = "logistics_search_shipment_read";
  FeatureName["LogisticsSearchUseCarrierSearch"] = "logistics_search_useCarrierSearch";
})(FeatureName || (FeatureName = {}));
export const SPLITS_WITH_DEFAULT_ON_TREATMENT = [FeatureName.Notifications];