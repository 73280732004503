export const dateToOpenSearchFilter = dateType => {
  if (dateType === "OLDER_THAN_ONE_WEEK") {
    const d = new Date();
    return [{
      range: {
        originalOrderCreationTimeUnix: {
          lt: Math.floor(d.setDate(d.getDate() - 7))
        }
      }
    }];
  }
  if (dateType === "LAST_MONTH") {
    const d = new Date();
    return [{
      range: {
        originalOrderCreationTimeUnix: {
          gt: Math.floor(d.setDate(d.getDate() - 31))
        }
      }
    }];
  }
  return [];
};