import { format } from "date-fns-tz";
import { useState } from "react";
import { useIntl } from "react-intl";
import { SlashDate } from "common/date/DateFormat";
import { subLocalTimeZoneOffset } from "common/date/subLocalTimeZoneOffset";
const DEFAULT_DATE_FORMAT = SlashDate;
export function useDatePicker({
  format: dateFormat,
  onDateChange,
  adjustUTCOffset
}) {
  const {
    formatMessage
  } = useIntl();
  const [visible, setVisible] = useState(false);
  const handleSelect = date => {
    if (date) {
      onDateChange(adjustUTCOffset ? subLocalTimeZoneOffset(date) : date);
      setVisible(false);
    } else {
      onDateChange(date);
    }
  };
  const handleVisibleChange = isVisible => setVisible(isVisible);
  const formatDate = subject => subject && format(subject, dateFormat ?? DEFAULT_DATE_FORMAT);
  const placeholder = formatMessage({
    id: "DatePicker.placeholder",
    defaultMessage: "Select a date"
  });
  return {
    visible,
    handleSelect,
    handleVisibleChange,
    formatDate,
    placeholder
  };
}