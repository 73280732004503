import { DistributionChannel } from "@deliverr/replenishment-client";
import { isEmpty } from "lodash";
export const UNKNOWN_DISTRIBUTION_CHANNEL = "UNKNOWN_DISTRIBUTION_CHANNEL";
export let OpenSearchInvalidOrderItemStatus;
(function (OpenSearchInvalidOrderItemStatus) {
  OpenSearchInvalidOrderItemStatus["ACTION_NEEDED"] = "ACTION_NEEDED";
})(OpenSearchInvalidOrderItemStatus || (OpenSearchInvalidOrderItemStatus = {}));
export const ReplenishmentEcomDistributionChannels = [DistributionChannel.ECOM_VIA_CROSSDOCK, DistributionChannel.ECOM_DIRECT_TO_FC, DistributionChannel.ECOM_VIA_HUB];
export const ReplenishmentAmazonDistributionChannels = [DistributionChannel.FBA, DistributionChannel.FBA_MANUAL];
export const ReplenishmentWalmartDistributionChannels = [DistributionChannel.WFS];
export const isFlexportEcomTransfer = item => !isEmpty(item.distributionChannel) && ReplenishmentEcomDistributionChannels.includes(item.distributionChannel);
export const isAmazonTransfer = item => !isEmpty(item.distributionChannel) && ReplenishmentAmazonDistributionChannels.includes(item.distributionChannel);
export const isWalmartTransfer = item => !isEmpty(item.distributionChannel) && ReplenishmentWalmartDistributionChannels.includes(item.distributionChannel);
export const isMultiShipmentReplenishmentOrder = item => (isEmpty(item.shipments) ? 0 : item.shipments.length) > 1;