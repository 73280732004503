import useSWR from "swr";
import { toast } from "common/components/ui";
import { useIntl } from "react-intl";
import { logStart, logError } from "Logger";
import { searchServiceFor } from "common/search/Factory";
import { ListType } from "common/list";
export function useLogisticsCarrierSearchService({
  searchTerm,
  filters,
  refreshIntervalMs,
  allowEmptySearchTerm,
  isLogisticsSearchEnabled = false
}) {
  const {
    formatMessage
  } = useIntl();
  const ctx = {
    fn: "useLogisticsCarrierSearchService",
    searchTerm,
    filters
  };
  const carrierOpenSearchService = searchServiceFor(ListType.Carriers);
  const isValidSearchTerm = searchTerm !== null && (searchTerm.length > 0 || allowEmptySearchTerm);
  const key = isValidSearchTerm && isLogisticsSearchEnabled ? ["carrierOpenSearch", searchTerm] : null;
  const {
    error,
    data
  } = useSWR(key, async ([_, term]) => {
    logStart(ctx);
    carrierOpenSearchService.clearCache();
    return await carrierOpenSearchService.execute({
      searchTerm: term,
      filters
    });
  }, {
    onError: err => {
      logError(ctx, err, `error retrieving carrier index`);
      toast.error(formatMessage({
        id: "useLogisticsCarrierSearchService.errorMessage",
        defaultMessage: "Error searching carriers"
      }), {
        toastId: "carrierOpenSearchServiceError"
      });
    },
    revalidateOnFocus: false,
    // don't refresh automatically when the seller returns focus to the page
    refreshInterval: refreshIntervalMs
  });
  return {
    data,
    error
  };
}