import { SortDir } from "common/models";
import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { ReplenishmentOrderListTabFilter } from "./ReplenishmentListState";
import { isEmpty } from "lodash/fp";
import { ReplenishmentDestinationType } from "./ReplenishmentDestinationType";
import { ReplenishmentListStatuses } from "../ReplenishmentListStatuses";
import { DistributionChannel } from "@deliverr/replenishment-client";
import { ReplenishmentListActionTypes } from "../actions/ReplenishmentListActionTypes";
import produce from "immer";
export const replenishmentListInitialState = {
  searchTerm: "",
  sort: {
    fieldName: "createdAt",
    direction: SortDir.DESC
  },
  pageNum: 0,
  searchFilters: {
    orderStatuses: [...ReplenishmentListStatuses],
    destinationType: ReplenishmentDestinationType.ALL,
    tabFilter: ReplenishmentOrderListTabFilter.ALL_ORDERS
  },
  showChannelReplenishmentBanner: {
    [DistributionChannel.FBA]: true,
    [DistributionChannel.WFS]: true
  }
};
const reducers = {
  ...handleSimpleReducerUpdates([ReplenishmentListActionTypes.REPLENISHMENT_LIST_CHANGE_PAGE_NUM, ReplenishmentListActionTypes.REPLENISHMENT_LIST_GET_ERROR]),
  [ReplenishmentListActionTypes.REPLENISHMENT_LIST_CHANGE_DESTINATION_FILTERS]: (state, {
    destinationType
  }) => produce(state, draft => {
    draft.searchFilters.destinationType = destinationType;
    draft.pageNum = 0;
  }),
  [ReplenishmentListActionTypes.REPLENISHMENT_LIST_CHANGE_STATUS_FILTERS]: (state, {
    orderStatuses
  }) => produce(state, draft => {
    draft.searchFilters.orderStatuses = orderStatuses;
    draft.pageNum = 0;
  }),
  [ReplenishmentListActionTypes.REPLENISHMENT_LIST_CHANGE_SEARCH_TERM]: (state, {
    searchTerm
  }) => produce(state, draft => {
    draft.searchTerm = searchTerm;
    if (isEmpty(searchTerm)) {
      draft.pageItems = undefined;
    }
    draft.pageNum = 0;
  }),
  [ReplenishmentListActionTypes.REPLENISHMENT_LIST_GET_SUCCESS]: (state, {
    listData: {
      pageItems,
      nbPages,
      pageNum
    }
  }) => produce(state, draft => {
    draft.pageItems = pageItems;
    draft.nbPages = nbPages;
    draft.pageNum = pageNum;
    draft.isError = false;
  }),
  [ReplenishmentListActionTypes.DELETE_REPLENISHMENT_ORDER_SUCCESS]: (state, {
    orderIds
  }) => produce(state, draft => {
    draft.pageItems = draft.pageItems?.filter(pageItem => !orderIds.includes(pageItem.orderId));
    draft.isError = false;
  }),
  [ReplenishmentListActionTypes.REPLENISHMENT_LIST_SET_TAB_FILTER]: (state, {
    tabFilter
  }) => produce(state, draft => {
    draft.searchFilters.tabFilter = tabFilter;
    draft.pageNum = 0;
  }),
  [ReplenishmentListActionTypes.UNSET_SHOW_CHANNEL_REPLENISHMENT_BANNER]: (state, {
    distributionChannel
  }) => produce(state, draft => {
    draft.showChannelReplenishmentBanner[distributionChannel] = false;
  })
};
export const replenishmentListReducer = createReducer(replenishmentListInitialState, reducers);