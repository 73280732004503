import { isEmpty, set } from "lodash";
import { SearchService } from "../SearchService";
import { LogisticsSearchClient } from "@deliverr/logistics-search-client";
import { getBearerToken } from "../../clients/core/getBearerToken";
import { logError } from "Logger";
import { addOpenSearchFilters, convertAlgoliaFiltersToOpenSearch } from "./OpenSearchUtils";
export class OpenSearchService extends SearchService {
  constructor(config) {
    super();
    this.config = config;
    this.client = new LogisticsSearchClient(config.indexName, {
      baseUrl: process.env.LOGISTICS_SEARCH_BASE_URL,
      auth: {
        token: getBearerToken
      }
    });
  }
  async execute(request) {
    try {
      const searchOptions = this.buildSearchOptions(request);

      // Setup search options
      const size = request.pageSize ?? this.config.searchConfig.hitsPerPage;

      // Setup request params
      const requestParams = searchOptions.hydrate ? {
        hydrate: true
      } : undefined;

      // Search query
      const results = await this.client.search({
        query: searchOptions.query,
        sort: searchOptions.sort,
        page: searchOptions.page,
        size: searchOptions.size,
        highlight: searchOptions.highlight
      }, requestParams);
      return this.processSearchResults(results, size, request);
    } catch (err) {
      // Log error but fail silently. Return no results.
      // TBD if we want to notify the user of the issue.
      logError({
        fn: "OpenSearchService.execute"
      }, `Error while querying logistics search platform: ${err.message}`);
    }
    return {
      hits: [],
      response: {
        nbHits: 0,
        nbPages: 0,
        hitsPerPage: 1,
        page: 1
      }
    };
  }
  getHighlightOverrides(highlight) {
    const resultObject = {};
    for (const key in highlight) {
      // Note: This method doesn't handle arrays correctly
      set(resultObject, key, highlight[key][0]);
    }
    return resultObject;
  }
  buildSearchOptions(request) {
    const size = request.pageSize ?? this.config.searchConfig.hitsPerPage;
    const hasLegacyQueries = (request.filters?.length ?? 0) > 0 || request.numericFilter;
    const baseQuery = hasLegacyQueries ? convertAlgoliaFiltersToOpenSearch(request) : {};
    const openSearchQuery = addOpenSearchFilters(baseQuery, request.customizedOpenSearchFilters ?? []);

    // Setup search options
    let searchOptions = {
      query: openSearchQuery,
      page: (request.page ?? 0) + 1,
      // page is 1-based (https://github.com/deliverr/logistics-search/blob/36f75b0dd8b0893ab38677ee28c8a9de02b37c2e/services/search/src/gateways/SearchGateway.ts#L41)
      size
    };

    // Add sort to search if exists with correct syntax
    if (request?.sort && request?.sort?.fieldName) {
      const sort = [{
        [request.sort.fieldName]: {
          order: request.sort.direction,
          ...(request.sort.missing && {
            missing: request.sort.missing
          }),
          ...(request.sort.unmappedType && {
            unmapped_type: request.sort.unmappedType
          })
        }
      }];
      searchOptions = {
        ...searchOptions,
        sort
      };
    }
    return searchOptions;
  }
  processSearchResults(results, size, request) {
    const hits = results.hits.hits.map(hit => {
      let rsp = {
        id: hit._id,
        ...hit._source,
        raw: {
          ...hit._source
        }
      };
      if (hit.highlight) {
        rsp = {
          ...rsp,
          ...this.getHighlightOverrides(hit.highlight)
        };
      }
      return rsp;
    });
    const nbPages = Math.ceil(results.hits?.total.value / size);
    return {
      hits,
      response: {
        nbHits: results.hits?.total.value,
        nbPages,
        hitsPerPage: size,
        page: request?.page ?? 1
      }
    };
  }
  async searchByIds(ids, query, idField, resultsSize) {
    const queryString = isEmpty(ids) ? "" : `(${ids.map(id => `${idField}:${id}`).join(" OR ")})`;
    const size = resultsSize ?? 1000;
    const results = await this.client.search({
      query: {
        bool: {
          filter: [{
            query_string: {
              query: queryString,
              analyze_wildcard: true
            }
          }]
        }
      },
      size: resultsSize
    });
    const hits = results.hits.hits.map(hit => {
      return {
        ...hit._source,
        raw: {
          ...hit._source
        }
      };
    });
    const nbPages = Math.ceil(results.hits?.total.value / size);
    return {
      hits,
      response: {
        nbHits: results.hits?.total.value,
        nbPages,
        hitsPerPage: size,
        page: 1
      }
    };
  }
  async getRecordByObjectId(objectId) {
    return {};
  }
  async getAllRecordsByObjectIds(objectIds) {
    return {};
  }
}