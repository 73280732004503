import { WholesaleIngestionChannel } from "../store/WholesaleListState";
import { compact } from "lodash";
import { ReplenishmentOrderListTabFilter } from "transfers/listV2/store/ReplenishmentListState";
import { DistributionChannel, NonComplianceStatus, OrderStatus } from "@deliverr/replenishment-client";
import { SPS_CHANNELS } from "../WholesaleListIngestionChannelFilter";
import { OpenSearchInvalidOrderItemStatus } from "transfers/listV2/ReplenishmentListUtils";
export const constructWholesaleListOpenSearchFilters = (searchTerm, {
  statuses,
  tabFilter,
  ingestionChannel
}, isWholesaleOrderHeldStateOn, shouldShowAdminFeatures) => {
  const searchTermFilters = getSearchTermFilters(searchTerm);
  const statusFilter = getOrderStatusFilter(statuses, tabFilter);
  const ingestionChannelFilter = getIngestionChannelFilter(ingestionChannel);
  const tabFilterQuery = getTabFilter(tabFilter);
  const mustFilters = getMustFilters();
  const mustNotFilters = getMustNotFilters(ingestionChannel, shouldShowAdminFeatures, isWholesaleOrderHeldStateOn);
  const filters = compact([ingestionChannelFilter, statusFilter, tabFilterQuery]);
  return [{
    bool: {
      ...searchTermFilters,
      filter: filters,
      must: mustFilters,
      must_not: mustNotFilters
    }
  }];
};
const getSearchTermFilters = searchTerm => {
  if (!searchTerm) {
    return {};
  }
  return {
    should: [{
      query_string: {
        query: `orderId:*${searchTerm}* OR distributionChannelOrderId:*${searchTerm}* OR name:*${searchTerm}*`,
        analyze_wildcard: true
      }
    }, {
      nested: {
        path: "shipments",
        query: {
          query_string: {
            query: `shipments.distributionChannelShipmentId:*${searchTerm}* OR shipments.poNumber:*${searchTerm}* OR shipments.shipmentId:*${searchTerm}*`,
            analyze_wildcard: true
          }
        }
      }
    }, {
      nested: {
        path: "orderItems",
        query: {
          term: {
            "orderItems.dsku": `${searchTerm}`
          }
        }
      }
    }, {
      nested: {
        path: "orderItems",
        query: {
          term: {
            "orderItems.packOf": `${searchTerm}`
          }
        }
      }
    }],
    minimum_should_match: 1
  };
};
const getTabFilter = tabFilter => {
  return tabFilter === ReplenishmentOrderListTabFilter.HAS_PROBLEM ? {
    bool: {
      should: [{
        term: {
          nonComplianceStatus: NonComplianceStatus.CREATED
        }
      }, {
        bool: {
          must: [{
            term: {
              invalidOrderItemStatus: OpenSearchInvalidOrderItemStatus.ACTION_NEEDED
            }
          }, {
            term: {
              status: OrderStatus.DRAFT
            }
          }]
        }
      }],
      minimum_should_match: 1
    }
  } : null;
};
const getIngestionChannelFilter = ingestionChannel => ingestionChannel === WholesaleIngestionChannel.SPS ? {
  terms: {
    ingestionChannel: SPS_CHANNELS
  }
} : null;
const getOrderStatusFilter = (orderStatuses, tabFilter) => {
  if (tabFilter === ReplenishmentOrderListTabFilter.HAS_PROBLEM) {
    return null;
  }
  return {
    terms: {
      // CREATED status behaves exactly like DRAFT for UI purposes
      status: orderStatuses.includes(OrderStatus.DRAFT) ? [...orderStatuses, OrderStatus.CREATED] : orderStatuses
    }
  };
};
const getMustFilters = () => [{
  term: {
    distributionChannel: DistributionChannel.WHOLESALE
  }
}];
const getMustNotFilters = (ingestionChannel, shouldShowAdminFeatures, isWholesaleOrderHeldStateOn) => {
  const mustNotFilters = [{
    terms: {
      status: isWholesaleOrderHeldStateOn ? [OrderStatus.ARCHIVED, OrderStatus.ERROR] : [OrderStatus.ARCHIVED, OrderStatus.ERROR, OrderStatus.DRAFT, OrderStatus.CREATED]
    }
  }];
  if (!shouldShowAdminFeatures) {
    mustNotFilters.push({
      term: {
        ingestionChannel: "SPS_TEST"
      }
    });
  }
  if (ingestionChannel === WholesaleIngestionChannel.OTHERS) {
    mustNotFilters.push({
      terms: {
        ingestionChannel: SPS_CHANNELS
      }
    });
  }
  return mustNotFilters;
};