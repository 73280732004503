import { sellerClient, userClient } from "common/clients/instances";
import { createActionCreator } from "common/ReduxUtils";
import { getUser } from "common/utils/SSO";
import { fetchBoxLabelsDefaults } from "inbounds/store/actions/boxLabels/fetchBoxLabelsDefaults";
import { isMatch } from "lodash";
import { omit, pick } from "lodash/fp";
import { logError, logStart } from "Logger";
import { getIsAdmin } from "./UserSelectors";
export let UserActionTypes;
(function (UserActionTypes) {
  UserActionTypes["REFRESH_USER_SUCCESS"] = "REFRESH_USER_SUCCESS";
  UserActionTypes["REFRESH_USER_ERROR"] = "REFRESH_USER_ERROR";
  UserActionTypes["UPDATE_USER_STATE"] = "UPDATE_USER_STATE";
  UserActionTypes["UPDATE_LOCAL_USER"] = "UPDATE_LOCAL_USER";
  UserActionTypes["UPDATE_EMAIL_VERIFICATION_PASS_STATUS"] = "UPDATE_EMAIL_VERIFICATION_PASS_STATUS";
  UserActionTypes["UPDATE_LOCAL_ONBOARDING_STAGE"] = "UPDATE_LOCAL_ONBOARDING_STAGE";
  UserActionTypes["UPDATE_CHARGEBEE_SESSION"] = "UPDATE_CHARGEBEE_SESSION";
  UserActionTypes["VOTE"] = "VOTE";
  UserActionTypes["SET_SPLIT_LOADED"] = "SET_SPLIT_LOADED";
  UserActionTypes["FETCH_BOX_LABELS_DEFAULTS"] = "FETCH_BOX_LABELS_DEFAULTS";
  UserActionTypes["SET_BOX_LABELS_DEFAULTS"] = "SET_BOX_LABELS_DEFAULTS";
  UserActionTypes["SET_PALLET_LABELS_DEFAULTS"] = "SET_PALLET_LABELS_DEFAULTS";
  UserActionTypes["SET_IS_MOBILE"] = "SET_IS_MOBILE";
  UserActionTypes["ASSOCIATE_USER_WITH_CONTACT_AREA_START"] = "ASSOCIATE_USER_WITH_CONTACT_AREA_START";
  UserActionTypes["ASSOCIATE_USER_WITH_CONTACT_AREA_SUCCESS"] = "ASSOCIATE_USER_WITH_CONTACT_AREA_SUCCESS";
  UserActionTypes["ASSOCIATE_USER_WITH_CONTACT_AREA_ERROR"] = "ASSOCIATE_USER_WITH_CONTACT_AREA_ERROR";
})(UserActionTypes || (UserActionTypes = {}));
export const updateUserState = createActionCreator(UserActionTypes.UPDATE_USER_STATE, "userState");
export const updateUser = (isOnWaitlist, isLinkedToFlexport, isPassEmailVerification) => {
  const action = {
    type: UserActionTypes.UPDATE_LOCAL_USER,
    ...{
      isOnWaitlist,
      isLinkedToFlexport
    }
  };
  if (isPassEmailVerification !== undefined) {
    action.isPassEmailVerification = isPassEmailVerification;
  }
  return action;
};
export const updatePassingEmailVerificationStatus = isPassEmailVerification => {
  return {
    type: UserActionTypes.UPDATE_LOCAL_USER,
    ...{
      isPassEmailVerification
    }
  };
};
export const updateLocalUser = user => ({
  type: UserActionTypes.UPDATE_LOCAL_USER,
  ...user
});
export const refreshUser = (bypassCognitoCache = false) => async (dispatch, getState) => {
  const isAdmin = getIsAdmin(getState());
  const shouldBypassCache = isAdmin || bypassCognitoCache;
  try {
    const user = await getUser({
      bypassCache: shouldBypassCache
    });
    if (user) {
      dispatch({
        user,
        type: UserActionTypes.REFRESH_USER_SUCCESS
      });
    }
  } catch (err) {
    dispatch({
      err,
      type: UserActionTypes.REFRESH_USER_ERROR
    });
  }
};
export const vote = id => ({
  type: UserActionTypes.VOTE,
  id
});
export const setSplitLoaded = splitLoaded => dispatch => {
  dispatch({
    type: UserActionTypes.SET_SPLIT_LOADED,
    splitLoaded
  });
};
const mutableSettingsList = ["isInternationalShippingEnabled"];
export const getMutableSettings = pick(mutableSettingsList);
export const getImmutableSettings = omit(mutableSettingsList);
export const getSellerSettings = sellerId => async dispatch => {
  const ctx = logStart({
    fn: "getSellerSettings",
    sellerId
  });
  try {
    const settings = await sellerClient.getSettings(sellerId);
    const mutableSettings = getMutableSettings(settings);
    const immutableSettings = getImmutableSettings(settings);
    dispatch(fetchBoxLabelsDefaults());
    dispatch(updateLocalUser({
      ...mutableSettings,
      settings: immutableSettings
    }));
  } catch (err) {
    // Disable logError until service is more reliable / is used on a non-beta service
    logError(ctx, err);
  }
};
export const getUserFromOrganization = async email => {
  const ctx = logStart({
    fn: "getUserFromOrganization",
    email
  });
  try {
    const userClientRes = await userClient.getUserFromOrganization(email);
    return {
      sellerId: userClientRes?.data.sellerId,
      isOnWaitlist: userClientRes?.data.isOnWaitlist
    };
  } catch (err) {
    logError(ctx, err);
    return {
      sellerId: null,
      isOnWaitlist: false
    };
  }
};
export const syncOnboardingExperiments = () => async (dispatch, getState) => {
  const ctx = logStart({
    fn: "syncOnboardingExperiments"
  });
  try {
    const {
      user: {
        sellerId,
        isInternationalShippingEnabled,
        settings
      },
      organization: {
        onboardingStage
      }
    } = getState();

    // Don't sync if user has connected an integration
    if (onboardingStage?.listingTool) {
      return;
    }
    const {
      experimentalValues = {}
    } = settings;
    const currentOnboardingExperimentValues = {};
    // Don't sync if all onboarding experiment values are already synced
    if (isMatch(experimentalValues, currentOnboardingExperimentValues)) {
      return;
    }
    await sellerClient.createOrUpdateSettings(sellerId, {
      sellerId,
      isInternationalShippingEnabled,
      experimentalValues: {
        ...experimentalValues,
        ...currentOnboardingExperimentValues
      }
    });
    const immutableSettings = getImmutableSettings(settings);
    dispatch(updateLocalUser({
      settings: {
        ...immutableSettings,
        experimentalValues: {
          ...experimentalValues,
          ...currentOnboardingExperimentValues
        }
      }
    }));
  } catch (err) {
    logError(ctx, err);
  }
};
export const sendEmailVerification = async (email, isAutoLinkUser) => {
  const ctx = logStart({
    fn: "sendEmailVerification",
    email
  });
  try {
    let userClientRes;
    if (isAutoLinkUser) {
      userClientRes = await userClient.sendAutoEmailVerification(email);
    } else {
      userClientRes = await userClient.sendEmailVerification(email);
    }
    return userClientRes?.status;
  } catch (err) {
    logError(ctx, err);
    throw err;
  }
};
export const fetchIsFlexportUserConnected = async email => {
  const ctx = logStart({
    fn: "fetchIsFlexportUserConnected",
    email
  });
  try {
    const userClientRes = await userClient.getFlexportUser(email);
    return Boolean(userClientRes?.data?.data?.hasValidLink);
  } catch (err) {
    logError(ctx, err);
    throw err;
  }
};
export const fetchIsExistingFlexportCompany = async email => {
  const ctx = logStart({
    fn: "fetchIsExistingFlexportCompany",
    email
  });
  try {
    const userClientRes = await userClient.getFlexportUser(email);
    return userClientRes?.data.data.monolithCompanyCreatedBy === "MONOLITH";
  } catch (err) {
    logError(ctx, err);
    throw err;
  }
};