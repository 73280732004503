import { OpenSearchService } from "common/search/services/OpenSearchService";
const SEARCH_TERM_WORD_LIMIT = 5;
const SEARCH_TERM_ID_WORD_LIMIT = 4;
export class OrderSearchService extends OpenSearchService {
  searchableAttributesShippingAddress = ["name", "phone", "street1", "street2", "city", "state", "zip", "country"];
  searchableAttributesCleanShippingAddress = [...this.searchableAttributesShippingAddress, "email"];
  searchableKeywords = ["objectID", "id", "marketplaceId", "error", "status", "items.dsku"];
  searchableText = [...this.searchableAttributesCleanShippingAddress.map(f => `cleanShippingAddress.${f}`), ...this.searchableAttributesShippingAddress.filter(f => !["name", "street1"].includes(f)).map(f => `originalShippingAddress.${f}`)];
  searchableFuzzyText = ["originalShippingAddress.name", "originalShippingAddress.street1", "items.msku"];
  searchTermDelimiter = /[^a-zA-Z0-9]+/;
  buildSearchOptions(request) {
    const searchOptions = super.buildSearchOptions(request);
    const boolShouldQueries = searchOptions?.query?.bool?.should ?? [];
    const shouldQueries = Array.isArray(boolShouldQueries) ? boolShouldQueries : [boolShouldQueries];
    if (request.searchTerm) {
      const searchTerm = request.searchTerm;
      const searchWords = searchTerm.split(this.searchTermDelimiter);
      shouldQueries.push({
        multi_match: {
          query: `${searchTerm}`,
          fields: this.searchableFuzzyText,
          fuzziness: searchWords.length < SEARCH_TERM_WORD_LIMIT ? "AUTO" : 0,
          operator: "and",
          analyzer: "autocomplete_search",
          max_expansions: searchWords.length < SEARCH_TERM_WORD_LIMIT ? Math.round(50 / searchWords.length) : 1
        }
      });
      shouldQueries.push({
        multi_match: {
          query: `${searchTerm}`,
          fields: ["marketplaceOrderId"],
          fuzziness: searchWords.length < SEARCH_TERM_ID_WORD_LIMIT ? "AUTO" : 0,
          operator: "and",
          analyzer: "autocomplete_search",
          max_expansions: searchWords.length < SEARCH_TERM_ID_WORD_LIMIT ? Math.round(50 / searchWords.length) : 1
        }
      });
      shouldQueries.push({
        multi_match: {
          query: `${searchTerm}`,
          type: "bool_prefix",
          fields: [...this.searchableText],
          operator: "and"
        }
      });
      for (const fieldName of this.searchableKeywords) {
        shouldQueries.push({
          prefix: {
            [fieldName]: `${searchTerm}`
          }
        });
      }
    }
    const additionalSearchOptions = {
      query: {
        bool: {
          ...(searchOptions?.query?.bool ?? {}),
          should: shouldQueries,
          minimum_should_match: shouldQueries.length > 0 ? 1 : 0
        }
      }
    };
    if (this.config.searchConfig.highlightMatches && request.highlightMatches !== false) {
      additionalSearchOptions.highlight = {
        fields: {
          "cleanShippingAddress.name": {},
          "originalShippingAddress.name": {}
        }
      };
      additionalSearchOptions.hydrate = true;
    }
    return {
      ...searchOptions,
      ...additionalSearchOptions
    };
  }
}