import RouteParser from "common/RouteParser";
export let Path;

// Used to associate paths with the inbounds sidebar link
(function (Path) {
  Path["root"] = "/";
  Path["loginRedirect"] = "/login-redirect";
  Path["emailVerificationRedirect"] = "/email-verification-redirect";
  Path["headlessLogin"] = "#__headlessLogin";
  Path["billing"] = "/billing";
  Path["billingNewCredit"] = "/billing/credits/new";
  Path["brandedPackagingList"] = "/branded-packaging";
  Path["newBrandedPackaging"] = "/branded-packaging/new";
  Path["brandedPackagingDetail"] = "/branded-packaging/:dsku";
  Path["lotExpiration"] = "/lot-expiration";
  Path["stopShipByDays"] = "/stop-ship-by-days";
  Path["egd"] = "/fast-tags/egd/:page";
  Path["egdStart"] = "/fast-tags/egd/start";
  Path["egdComplete"] = "/fast-tags/egd/complete";
  Path["egdCutoff"] = "/fast-tags/egd/cutoff";
  Path["egdOAuth"] = "/fast-tags/egd/oauth";
  Path["egdRateTable"] = "/fast-tags/egd/rate-table";
  Path["fastTagConnect"] = "/fast-tags";
  Path["fastTagShopDetail"] = "/fast-tags/:program/shop/:shop";
  Path["fastTagDetail"] = "/fast-tags/:program";
  Path["fastTagsProgram"] = "/fast-tags/:program/:page";
  Path["financing"] = "/financing";
  Path["financingSettings"] = "/financing/settings";
  Path["financingRequestForm"] = "/financing/request-form";
  Path["freightQuoteTool"] = "/freight-quote";
  Path["freightQuoteSummary"] = "/freight-quote/:freightQuoteId";
  Path["freight"] = "/freight/bookings";
  Path["freightBookingSetup"] = "/freight/booking/setup/:quoteId";
  Path["freightBookingSuccess"] = "/freight/booking/success";
  Path["freightOrderDetail"] = "/freight/order/:orderId";
  Path["publicFreightQuoteSummary"] = "/public/freight-quote/:freightQuoteId";
  Path["publicFreightQuoteSummaryRoot"] = "/public/freight-quote";
  Path["prep"] = "/prep";
  Path["prepDetails"] = "/prep/detail/:prepId";
  Path["inboundsList"] = "/inbounds";
  Path["inboundsSelectDestinationType"] = "/inbounds/type";
  Path["inboundsSelectProducts"] = "/inbounds/new";
  Path["inboundsShippingPlanRedirect"] = "/inbounds/:shippingPlanId";
  Path["inboundsShippingPlanProducts"] = "/inbounds/:shippingPlanId/products";
  Path["inboundsLotInformation"] = "/inbounds/:shippingPlanId/lot-information";
  Path["inboundsAssignCategories"] = "/inbounds/:shippingPlanId/categories";
  Path["inboundsPackagingRequirements"] = "/inbounds/:shippingPlanId/packaging-requirements";
  Path["inboundsCustoms"] = "/inbounds/:shippingPlanId/customs";
  Path["inboundsBarcodeInput"] = "/inbounds/:shippingPlanId/barcode";
  Path["inboundsBarcodePrint"] = "/inbounds/:shippingPlanId/barcode-print";
  Path["inboundsDeliverrPrep"] = "/inbounds/:shippingPlanId/deliverr-prep";
  Path["inboundsFromAddress"] = "/inbounds/:shippingPlanId/from-address";
  Path["inboundsDistributeSelect"] = "/inbounds/:shippingPlanId/distribute-select";
  Path["inboundsAddDimensions"] = "/inbounds/:shippingPlanId/dimensions";
  Path["inboundsShip"] = "/inbounds/:shippingPlanId/shipments/:shipmentId";
  Path["inboundsShipmentConfirmed"] = "/inbounds/:shippingPlanId/shipments/:shipmentId/shipment-confirmed";
  Path["inboundsShipAddDimensions"] = "/inbounds/:shippingPlanId/shipments/:shipmentId/dimensions";
  Path["inboundsSmbShipmentDetails"] = "/inbounds/:shippingPlanId/shipment-details";
  Path["inboundsSmbShipmentMessages"] = "/inbounds/:shippingPlanId/shipment-details/messages";
  Path["inboundsSmbShipmentTask"] = "/inbounds/:shippingPlanId/shipment-details/task/:taskId";
  Path["inboundsSmbShipmentProblem"] = "/inbounds/:shippingPlanId/shipment-details/problems/:problemId";
  Path["inboundsConnectAmazonTask"] = "/inbounds/:shippingPlanId/shipment-details/connect-amazon/:taskId";
  Path["inboundsAddProductsTask"] = "/inbounds/:shippingPlanId/shipment-details/add-products/:taskId";
  Path["inboundsAddCasePacksTask"] = "/inbounds/:shippingPlanId/shipment-details/add-packing-information/:taskId";
  Path["inboundsAddFBADetailsTask"] = "/inbounds/:shippingPlanId/shipment-details/add-amazon-details/:taskId";
  Path["inboundsAddFBAShipmentReferenceIDTask"] = "/inbounds/:shippingPlanId/shipment-details/add-amazon-refID/:taskId";
  Path["inboundsDownloadBoxContentsLabelsTask"] = "/inbounds/:shippingPlanId/shipment-details/download-box-contents-labels/:taskId";
  Path["inboundsPackagingRequirementsTask"] = "/inbounds/:shippingPlanId/shipment-details/packaging-requirements/:taskId";
  Path["inboundsAddProductsAndBoxConfigurationsTask"] = "/inbounds/:shippingPlanId/shipment-details/add-products-and-box-configurations/:taskId";
  Path["inboundsAddProductsAndUnitsTask"] = "/inbounds/:shippingPlanId/shipment-details/add-products-and-units/:taskId";
  Path["inboundsAddHTSCodeTask"] = "/inbounds/:shippingPlanId/shipment-details/add-hts-code/:taskId";
  Path["inboundsStorageSelectProducts"] = "/inbounds/storage/select-products";
  Path["inboundsStorageLotInformation"] = "/inbounds/storage/add-lot-information";
  Path["inboundsStorageDraftRedirect"] = "/inbounds/:shippingPlanId/storage";
  Path["inboundsStorageAddProductsBarcode"] = "/inbounds/storage/add-products-barcode";
  Path["inboundsStorageAddDimensions"] = "/inbounds/storage/select-dimensions";
  Path["inboundsStorageFromAddress"] = "/inbounds/storage/select-from-address";
  Path["inboundsStorageDestination"] = "/inbounds/storage/select-destination";
  Path["inboundsStorageCargoType"] = "/inbounds/storage/select-cargo-type";
  Path["inboundsStorageShipmentConfirmed"] = "/inbounds/:shippingPlanId/storage/shipment-confirmed";
  Path["inboundsStorageDetail"] = "/inbounds/:shippingPlanId/storage/detail";
  Path["inboundsStorageConfirmShipment"] = "/inbounds/storage/confirm-shipment";
  Path["inboundsStorageSelectShipping"] = "/inbounds/storage/select-shipping";
  Path["inboundsStorageAddPickupDetails"] = "/inbounds/storage/add-pickup-details";
  Path["inboundsOnboardingChecklist"] = "/inbounds?onboarding=true";
  Path["inboundsIPBOrigin"] = "/inbounds/ipb/:shippingPlanId?/origin";
  Path["inboundsIPBSupplier"] = "/inbounds/ipb/:shippingPlanId?/supplier";
  Path["inboundsIPBFulfillment"] = "/inbounds/ipb/:shippingPlanId?/fulfillment-network";
  Path["inboundsIPBFulfillmentDestination"] = "/inbounds/ipb/:shippingPlanId?/fulfillment-destination";
  Path["inboundsIPBDirectDestination"] = "/inbounds/ipb/:shippingPlanId?/direct-destination";
  Path["inboundsIPBReserveStorageLocation"] = "/inbounds/ipb/:shippingPlanId?/storage-location";
  Path["inboundsIPBCargoDetails"] = "/inbounds/ipb/:shippingPlanId?/cargo-details";
  Path["inboundsIPBPackagingRequirements"] = "/inbounds/ipb/:shippingPlanId?/packaging-requirements";
  Path["inboundsIPBPriceView"] = "/inbounds/ipb/:shippingPlanId?/checkout";
  Path["transfersList"] = "/transfers";
  Path["transferDetails"] = "/transfers/:orderId/shipments";
  Path["replenishmentDetails"] = "/replenishment/:orderId/shipments";
  Path["transfersNew"] = "/transfers/new";
  Path["transfersDraft"] = "/transfers/draft/:transferId";
  Path["inventoryDetail"] = "/inventory/:dsku";
  Path["inventoryDetailViewProblem"] = "/inventory/:dsku/:problemType/:problemId";
  Path["inventorySetupAsBundle"] = "/inventory/:dsku/setup-as-bundle";
  Path["inventoryAddComponents"] = "/inventory/:dsku/add-components";
  Path["inventoryList"] = "/inventory";
  Path["inventoryNewProduct"] = "/inventory/new";
  Path["inventoryNewBundle"] = "/inventory/bundles/new";
  Path["inventoryNewKitOrBundle"] = "/inventory/kits-and-bundles/new";
  Path["inventoryBundleDetail"] = "/inventory/bundles/:bsku";
  Path["login"] = "/login";
  Path["onboardingSignup"] = "/onboarding/signup";
  Path["onboarding"] = "/onboarding";
  Path["onboardingAllSet"] = "/onboarding/all-set";
  Path["onboardingImportingCatalog"] = "/onboarding/importing-catalog/:slsUuid?";
  Path["onboardingIntegrationSetup"] = "/onboarding/listing-tool-setup/:channel";
  Path["onboardingIntegrationSetupWithToken"] = "/onboarding/listing-tool-setup/:channel/:token";
  Path["onboardingChooseIntegration"] = "/onboarding/choose-your-integration";
  Path["onboardingIntegrationSetupComplete"] = "/onboarding/listing-tool-setup/complete/:channel";
  Path["onboardingCreateCatalog"] = "/onboarding/create-catalog";
  Path["onboardingRedirect"] = "/onboarding/redirect";
  Path["onboardingShopifySettings"] = "/onboarding/shopify-settings";
  Path["onboardingSurvey"] = "/onboarding/setup";
  Path["onboardingSmb"] = "/onboarding/smb";
  Path["onboardingSmbCompanyCountryOfRegistration"] = "/onboarding/smb/company/country-of-registration";
  Path["onboardingSmbCompany"] = "/onboarding/smb/company";
  Path["onboardingSmbTaxId"] = "/onboarding/smb/company/tax-id";
  Path["onboardingSmbCompanyDetails"] = "/onboarding/smb/company/details";
  Path["onboardingSmbCompanyIrsUpload"] = "/onboarding/smb/company/irs-upload";
  Path["onboardingSmbCompanyMailingAddress"] = "/onboarding/smb/company/mailing-address";
  Path["onboardingSmbCompanyPhysicalAddress"] = "/onboarding/smb/company/physical-address";
  Path["onboardingSmbSupplier"] = "/onboarding/smb/supplier";
  Path["onboardingSmbPickup"] = "/onboarding/smb/pickup";
  Path["onboardingSmbDelivery"] = "/onboarding/smb/delivery";
  Path["onboardingSmbAuthorize"] = "/onboarding/authorize";
  Path["onboardingSmbCustomsBond"] = "/onboarding/authorize/customs-bond";
  Path["onboardingSmbCustomsClearance"] = "/onboarding/authorize/customs-clearance";
  Path["onboardingSmbPowerOfAttorney"] = "/onboarding/authorize/power-of-attorney";
  Path["onboardingSmbPowerOfAttorneyDocusignRedirect"] = "/onboarding/authorize/power-of-attorney/docusign-redirect";
  Path["onboardingArticleOfIncorporationUpload"] = "/onboarding/smb/company/article-of-incorporation-upload";
  Path["orderDetail"] = "/orders/:orderId/detail";
  Path["orderList"] = "/orders";
  Path["orderNew"] = "/orders/new";
  Path["orderRemoval"] = "/orders/removal";
  Path["orderClaim"] = "/orders/claim/:orderId";
  Path["lotOrderRemoval"] = "/orders/lotRemoval";
  Path["wholesaleList"] = "/wholesale";
  Path["wholesaleListV2"] = "/wholesale-v2";
  Path["wholesaleDetail"] = "/wholesale/:orderId/detail";
  Path["wholesaleDetailV2"] = "/wholesale/:orderId/detail-v2";
  Path["wholesaleNew"] = "/wholesale/new";
  Path["wholesaleNewV2"] = "/wholesale/new-v2";
  Path["wholesaleDuplicate"] = "/wholesale/:orderId/duplicate";
  Path["wholesaleBulkImport"] = "/wholesale/import";
  Path["wholesaleBulkImportJob"] = "/wholesale/import/jobs/:jobId";
  Path["wholesaleBulkJobsList"] = "/wholesale/import/jobs";
  Path["reports"] = "/reports";
  Path["returnsCreate"] = "/returns/create/:orderId?";
  Path["returns"] = "/returns";
  Path["returnsLabel"] = "/returns/:returnId/orders/:orderId/label";
  Path["returnsList"] = "/returns";
  Path["returnsPolicy"] = "/returns/settings";
  Path["returnsDetail"] = "/returns/:returnId/orders/:orderId/detail";
  Path["returnsDetailWithId"] = "/returns/:returnId/details";
  Path["returnsRmtPolicy"] = "/returns/settings/:rmtId";
  Path["returnsRmtPolicyWithToken"] = "/returns/settings/:rmtId/:token";
  Path["returnsProblemDetails"] = "/returns/problems/:problemId";
  Path["returnsProblemList"] = "/returns/problems";
  Path["settings"] = "/settings";
  Path["settingsAccount"] = "/settings/account";
  Path["settingsAccountFlexportPlus"] = "/settings/account/plus";
  Path["settingsReplenishment"] = "/settings/replenishment";
  Path["settingsShippingOptionMapping"] = "/settings/shipping-option-mapping";
  Path["settingsInternationalShipping"] = "/settings/international-shipping";
  Path["settingsInternationalShippingCustoms"] = "/settings/international-shipping/customs";
  Path["settingsReferrals"] = "/settings/referrals";
  Path["settingsUsersList"] = "/settings/users";
  Path["settingsUsersNew"] = "/settings/users/new";
  Path["settingsUsersEdit"] = "/settings/users/edit";
  Path["settingsApiTokens"] = "/settings/api-tokens";
  Path["settingsApiTokensGenerate"] = "/settings/api-tokens/generate";
  Path["settingsApiTokensEdit"] = "/settings/api-tokens/edit";
  Path["settingsAcceptedAddresses"] = "/settings/accepted-addresses";
  Path["shopifyStart"] = "/fast-tags/shopify/start";
  Path["shopifyInstallApp"] = "/fast-tags/shopify/install-app";
  Path["shopifyEnableAppEmbed"] = "/fast-tags/shopify/enable-app-embed";
  Path["shopifyStoreCredit"] = "/fast-tags/shopify/store-credit";
  Path["shopifyProductDetailBadge"] = "/fast-tags/shopify/product-detail-badge";
  Path["shopifyOtherBadges"] = "/fast-tags/shopify/other-badges";
  Path["shopifyTurnOnBadges"] = "/fast-tags/shopify/turn-on-badges";
  Path["shopifyComplete"] = "/fast-tags/shopify/complete";
  Path["shopifyFastTagBadge"] = "/fast-tags/shopify/fast-tag-badge";
  Path["shopifyStandardBadgeV2"] = "/fast-tags/shopify/standard-badge";
  Path["shopifyInternationalBadge"] = "/fast-tags/shopify/international-badge";
  Path["walmart"] = "/fast-tags/walmart/:page";
  Path["walmartRequestAccess"] = "/fast-tags/walmart/request-access";
  Path["walmartConfigureTwoDay"] = "/fast-tags/walmart/configure";
  Path["walmartAcknowledge"] = "/fast-tags/walmart/acknowledge";
  Path["walmartComplete"] = "/fast-tags/walmart/complete";
  Path["walmartKeys"] = "/fast-tags/walmart/keys";
  Path["walmartStart"] = "/fast-tags/walmart/start";
  Path["walmartTimeZone"] = "/fast-tags/walmart/time-zone";
  Path["walmartUpdate"] = "/fast-tags/walmart/update";
  Path["googleStart"] = "/fast-tags/google/start";
  Path["googleVerifyShopify"] = "/fast-tags/google/verify-shopify";
  Path["googleSelectPrograms"] = "/fast-tags/google/select-programs";
  Path["googleLinkAccount"] = "/fast-tags/google/link-account";
  Path["googleSupplementalFeed"] = "/fast-tags/google/supplemental-feed";
  Path["googleComplete"] = "/fast-tags/google/complete";
  Path["facebookStart"] = "/fast-tags/facebook/start";
  Path["facebookConnectShopify"] = "/fast-tags/facebook/connect-shopify";
  Path["facebookConnectAccount"] = "/fast-tags/facebook/connect-account";
  Path["facebookAdsSettings"] = "/fast-tags/facebook/ads-settings";
  Path["facebookSavedAudienceWarning"] = "/fast-tags/facebook/saved-audience-warning";
  Path["facebookConfigureAds"] = "/fast-tags/facebook/configure-ads";
  Path["facebookSelectPixel"] = "/fast-tags/facebook/select-pixel";
  Path["facebookAdSetsCreated"] = "/fast-tags/facebook/ad-sets-created";
  Path["facebookAdSetsSync"] = "/fast-tags/facebook/ad-sets-sync";
  Path["facebookSelectAction"] = "/fast-tags/facebook/select-action";
  Path["wishStart"] = "/fast-tags/wish/start";
  Path["wishEnroll"] = "/fast-tags/wish/enroll";
  Path["wishLinkDeliverr"] = "/fast-tags/wish/link-deliverr";
  Path["wishActivate"] = "/fast-tags/wish/activate";
  Path["wishComplete"] = "/fast-tags/wish/complete";
  Path["barcodePage"] = "/barcodes/:dsku";
  Path["nonCompliance"] = "/inbounds/problems";
  Path["channels"] = "/channels/";
  Path["channelsEdit"] = "/channels/:slsUuid";
  Path["channelsEditLegacy"] = "/channels/edit/:channel";
  Path["channelsIntegrationSetup"] = "/channels/listing-tool-setup/:channel";
  Path["channelsUpdateCredentials"] = "/channels/:slsUuid/update-credentials";
  Path["shopifyApp"] = "/shopify-app";
  Path["parcel"] = "/parcel";
  Path["parcelIntegrations"] = "/parcel/integrations";
  Path["parcelDirectApi"] = "/parcel/integrations/direct-api";
  Path["parcelShipStation"] = "/parcel/integrations/shipstation";
  Path["parcelEasyPost"] = "/parcel/integrations/easypost";
  Path["parcelExtensiv"] = "/parcel/integrations/extensiv";
  Path["parcelProShip"] = "/parcel/integrations/proship";
  Path["parcelDetail"] = "/parcel/detail/:parcelId";
  Path["parcelClaim"] = "/parcel/claim/:parcelId";
})(Path || (Path = {}));
export const INBOUND_PATHS = [Path.inboundsList, Path.inboundsSelectDestinationType, Path.inboundsSelectProducts, Path.inboundsShippingPlanProducts, Path.inboundsLotInformation, Path.inboundsAssignCategories, Path.inboundsPackagingRequirements, Path.inboundsCustoms, Path.inboundsBarcodeInput, Path.inboundsBarcodePrint, Path.inboundsDeliverrPrep, Path.inboundsFromAddress, Path.inboundsDistributeSelect, Path.inboundsAddDimensions, Path.inboundsShip, Path.inboundsShipAddDimensions, Path.inboundsAddFBAShipmentReferenceIDTask, Path.inboundsSmbShipmentDetails, Path.inboundsSmbShipmentTask, Path.inboundsSmbShipmentProblem, Path.inboundsSmbShipmentMessages, Path.inboundsConnectAmazonTask, Path.inboundsAddProductsTask, Path.inboundsAddCasePacksTask, Path.inboundsAddFBADetailsTask, Path.inboundsStorageSelectProducts, Path.inboundsStorageLotInformation, Path.inboundsStorageAddProductsBarcode, Path.inboundsStorageAddDimensions, Path.inboundsStorageFromAddress, Path.inboundsStorageDestination, Path.inboundsStorageCargoType, Path.inboundsStorageSelectShipping, Path.inboundsStorageAddPickupDetails, Path.inboundsStorageShipmentConfirmed, Path.inboundsStorageConfirmShipment, Path.inboundsStorageDetail, /* start - IPB steps */
Path.inboundsIPBOrigin, Path.inboundsIPBSupplier, Path.inboundsIPBFulfillment, Path.inboundsIPBFulfillmentDestination, Path.inboundsIPBDirectDestination, Path.inboundsIPBCargoDetails, Path.inboundsIPBPriceView
/* end - IPB steps */];

export const RETURNS_PATH = [Path.returnsCreate, Path.returnsDetail, Path.returnsList, Path.returnsLabel];
export const REPLENISHMENT_PATHS = [Path.transfersList, Path.transferDetails];
export const BRANDED_PACKAGING_PATHS = [Path.brandedPackagingList, Path.newBrandedPackaging, Path.brandedPackagingDetail];
export const LOT_EXPIRATION_PATHS = [Path.lotExpiration];
export const FREIGHT_QUOTE_PATHS = [Path.freightQuoteTool, Path.freightQuoteSummary, Path.publicFreightQuoteSummary];
export const DynamicRoutes = {
  barcodePage: new RouteParser(Path.barcodePage),
  inventoryDetail: new RouteParser(Path.inventoryDetail),
  brandedPackagingDetail: new RouteParser(Path.brandedPackagingDetail),
  lotExpiration: new RouteParser(Path.lotExpiration),
  freightOrderDetail: new RouteParser(Path.freightOrderDetail),
  shippingPlanProducts: new RouteParser(Path.inboundsShippingPlanProducts),
  onboardingSetupPageWithToken: new RouteParser(Path.onboardingIntegrationSetupWithToken),
  orderDetail: new RouteParser(Path.orderDetail),
  returnsDetail: new RouteParser(Path.returnsDetail),
  inboundsShip: new RouteParser(Path.inboundsShip),
  inboundsShipAddDimensions: new RouteParser(Path.inboundsShipAddDimensions),
  inboundsShippingPlanRedirect: new RouteParser(Path.inboundsShippingPlanRedirect),
  inboundsStorageDetail: new RouteParser(Path.inboundsStorageDetail),
  inboundsAddFBAShipmentReferenceIDTask: new RouteParser(Path.inboundsAddFBAShipmentReferenceIDTask),
  inboundsSmbShipmentDetails: new RouteParser(Path.inboundsSmbShipmentDetails),
  inboundsSmbShipmentMessages: new RouteParser(Path.inboundsSmbShipmentMessages),
  inboundsSmbShipmentTask: new RouteParser(Path.inboundsSmbShipmentTask),
  inboundsSmbShipmentProblem: new RouteParser(Path.inboundsSmbShipmentProblem),
  inboundsConnectAmazonTask: new RouteParser(Path.inboundsConnectAmazonTask),
  inboundsAddProductsTask: new RouteParser(Path.inboundsAddProductsTask),
  inboundsAddCasePacksTask: new RouteParser(Path.inboundsAddCasePacksTask),
  inboundsAddFBADetailsTask: new RouteParser(Path.inboundsAddFBADetailsTask),
  replenishmentDetails: new RouteParser(Path.replenishmentDetails),
  wholesaleDetail: new RouteParser(Path.wholesaleDetail)
};