import { OrderError } from "common/clients/orders/DeliverrOrder/OrderError";
import { OrderStatus } from "common/clients/orders/DeliverrOrder/OrderStatus";
const STATUSES = Object.values(OrderStatus);
const buildStatusFilter = status => ({
  match: {
    status: `${status}`
  }
});
const buildErrorFilter = error => ({
  match: {
    error: `${error}`
  }
});
const ERROR_STATUS_FILTER = buildStatusFilter(OrderStatus.ERROR);
const CREATED_STATUS_FILTER = buildStatusFilter(OrderStatus.CREATED);
const SHIPPED_STATUS_FILTER = buildStatusFilter(OrderStatus.SHIPPED);

// Instructions on how to combine and construct filters:
// https://opensearch.org/docs/latest/query-dsl/query-filter-context/#filter-context
const openSearchFilters = {
  [OrderError.BAD_ADDRESS]: [ERROR_STATUS_FILTER, buildErrorFilter(OrderError.BAD_ADDRESS)],
  [OrderError.DSKU_NEVER_INBOUNDED]: [{
    terms: {
      status: [`${OrderStatus.ERROR}`, `${OrderStatus.CANCELLED}`]
    }
  }, buildErrorFilter(OrderError.DSKU_NEVER_INBOUNDED)],
  [OrderError.STOCKOUT]: [{
    terms: {
      status: [`${OrderStatus.ERROR}`, `${OrderStatus.CANCELLED}`]
    }
  }, {
    terms: {
      error: [`${OrderError.STOCKOUT}`, `${OrderError.STOCKOUT_AT_INGESTION}`]
    }
  }],
  [OrderStatus.CREATED]: [{
    terms: {
      status: [`${OrderStatus.CREATED}`, `${OrderStatus.ERROR}`]
    }
  }, {
    bool: {
      should: [CREATED_STATUS_FILTER, buildErrorFilter(OrderError.UNKNOWN)]
    }
  }],
  LATE: [{
    multi_match: {
      query: "1",
      fields: ["orderDetails.isEstimatedToShipLate", "orderDetails.isEstimatedToDeliverLate"]
    }
  }],
  IN_TRANSIT: [SHIPPED_STATUS_FILTER]
};
export const statusToOpenSearchFilter = statusFilter => {
  if (!statusFilter || statusFilter === "ALL_STATUS") {
    return [];
  }
  if (openSearchFilters[statusFilter]) {
    return openSearchFilters[statusFilter];
  }
  if (STATUSES.includes(statusFilter)) {
    return [buildStatusFilter(statusFilter)];
  }
  return [];
};