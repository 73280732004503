import { ListType } from "common/list";
import { getBearerToken } from "common/clients/core/getBearerToken";
import { getEnvString } from "@deliverr/commons-utils";

/**
 * Sets up all search configs required for listing pages.
 * The goal is to consolidate config creation by "list type".
 *
 * Search configs are pulled from Algolia by default and a
 * optional logisticsSearch flag can be passed to search from
 * the new Logistics Search platform.
 *
 * More info here
 * https://docs.google.com/document/d/11WgAJu_LuHfP5Dbo-sTEP-nnkDS3gy3IyI_nVNcoTFo/edit
 */

const DefaultLogisticsSearchConfig = {
  searchConfig: {
    hitsPerPage: 25,
    highlightMatches: true
  },
  auth: {
    token: getBearerToken
  }
};
const DefaultAlgoliaConfig = {
  searchConfig: {
    hitsPerPage: 25,
    maxValuesPerFacet: 3
  },
  highlightMatches: true
};

/**
 *
 * @param listType
 * @param lsEnabled Logistics search enabled (as opposed to Algolia by default)
 * @returns
 */
export const configFor = listType => {
  switch (listType) {
    case ListType.Inventory:
      return {
        ...DefaultAlgoliaConfig,
        indexName: process.env.ALGOLIA_INDEX_INVENTORY
      };
    case ListType.InventoryV2:
      return {
        ...DefaultLogisticsSearchConfig,
        indexName: getEnvString("OPENSEARCH_INDEX_INVENTORY", "products")
      };
    case ListType.Parcel:
      return {
        ...DefaultLogisticsSearchConfig,
        indexName: process.env.OPENSEARCH_INDEX_PARCEL
      };
    case ListType.Bundle:
      return {
        ...DefaultAlgoliaConfig,
        indexName: process.env.ALGOLIA_INDEX_BUNDLES
      };
    case ListType.BundleV2:
      return {
        ...DefaultLogisticsSearchConfig,
        indexName: getEnvString("OPENSEARCH_INDEX_BUNDLE", "bundles")
      };
    case ListType.Prep:
      return {
        ...DefaultLogisticsSearchConfig,
        indexName: process.env.OPENSEARCH_INDEX_PREP
      };
    case ListType.ClaimUploads:
      return {
        ...DefaultLogisticsSearchConfig,
        indexName: process.env.OPENSEARCH_INDEX_CLAIM
      };
    case ListType.ReserveStorageOutbounds:
      return {
        ...DefaultLogisticsSearchConfig,
        indexName: process.env.OPENSEARCH_INDEX_RESERVE_STORAGE_OUTBOUNDS
      };
    case ListType.InboundListV2:
      return {
        ...DefaultLogisticsSearchConfig,
        indexName: process.env.OPENSEARCH_INDEX_INBOUNDS
      };
    case ListType.Orders:
      return {
        ...DefaultLogisticsSearchConfig,
        indexName: getEnvString("OPENSEARCH_INDEX_ORDER", "fulfillment_deliverrorder")
      };
    case ListType.Carriers:
      return {
        ...DefaultLogisticsSearchConfig,
        indexName: process.env.OPENSEARCH_INDEX_CARRIERS
      };
    case ListType.OrderIngestErrors:
      return {
        ...DefaultLogisticsSearchConfig,
        indexName: getEnvString("OPENSEARCH_INDEX_ORDER_INGEST_ERRORS", "channels_order_ingest_errors")
      };
    default:
      throw new Error("Not implemented");
  }
};