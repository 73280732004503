import { OrderTag } from "@deliverr/commons-objects";
import { RemovalMethods } from "order/new/ShippingMethods";
export const serviceToOpenSearchFilter = serviceFilter => {
  if (serviceFilter === "ALL_SERVICE") {
    return [];
  }
  if (serviceFilter === RemovalMethods.REMOVAL) {
    return [{
      match: {
        tags: `${OrderTag.INVENTORY_REMOVAL}`
      }
    }];
  }
  if (Array.isArray(serviceFilter)) {
    return [{
      terms: {
        "orderDetails.serviceLevel": serviceFilter.map(service => `${service}`)
      }
    }];
  }
  if (serviceFilter) {
    return [{
      match: {
        "orderDetails.serviceLevel": `${serviceFilter}`
      }
    }];
  }
  return [];
};